<template>
  <v-row class="body-map full-body-map">
    <v-col cols="12" class="d-flex align-end justify-center pb-4">
      <div class="img-container large-right-hand-container d-inline-block">
        <v-img src="/imgs/large-right-hand.svg">
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-hand-thumb-metacarpophalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option == 'right-hand-thumb-metacarpophalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option == 'right-hand-thumb-metacarpophalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-hand-thumb-interphalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option == 'right-hand-thumb-interphalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option == 'right-hand-thumb-interphalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-hand-index-metacarpophalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option == 'right-hand-index-metacarpophalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option == 'right-hand-index-metacarpophalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-hand-index-proximal-interphalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option ==
                  'right-hand-index-proximal-interphalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option ==
                    'right-hand-index-proximal-interphalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-hand-index-distal-interphalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option ==
                  'right-hand-index-distal-interphalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option ==
                    'right-hand-index-distal-interphalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-hand-middle-metacarpophalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option == 'right-hand-middle-metacarpophalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option ==
                    'right-hand-middle-metacarpophalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-hand-middle-proximal-interphalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option ==
                  'right-hand-middle-proximal-interphalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option ==
                    'right-hand-middle-proximal-interphalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-hand-middle-distal-interphalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option ==
                  'right-hand-middle-distal-interphalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option ==
                    'right-hand-middle-distal-interphalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-hand-ring-metacarpophalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option == 'right-hand-ring-metacarpophalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option == 'right-hand-ring-metacarpophalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-hand-ring-proximal-interphalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option ==
                  'right-hand-ring-proximal-interphalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option ==
                    'right-hand-ring-proximal-interphalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-hand-ring-distal-interphalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option == 'right-hand-ring-distal-interphalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option ==
                    'right-hand-ring-distal-interphalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-hand-pinky-metacarpophalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option == 'right-hand-pinky-metacarpophalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option == 'right-hand-pinky-metacarpophalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-hand-pinky-proximal-interphalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option ==
                  'right-hand-pinky-proximal-interphalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option ==
                    'right-hand-pinky-proximal-interphalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-hand-pinky-distal-interphalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option ==
                  'right-hand-pinky-distal-interphalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option ==
                    'right-hand-pinky-distal-interphalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-wrist-joint ${'selected-joint'} ${
              updateMood ? 'update-mood' : ''
            }`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find((joint) => joint.option == 'right-wrist-joint') &
                updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
        </v-img>
        <span>{{$t("right_hand")}}</span>
      </div>
      <div class="img-container body-container d-inline-block">
        <v-img src="/imgs/body.svg">
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-elbow-joint ${
              joints.find((joint) => joint.option == 'right-elbow-joint')
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find((joint) => joint.option == 'right-elbow-joint') &
                updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-glenohumeral-joint ${
              joints.find((joint) => joint.option == 'right-glenohumeral-joint')
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) => joint.option == 'right-glenohumeral-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-acromioclavicular-joint ${
              joints.find(
                (joint) => joint.option == 'right-acromioclavicular-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) => joint.option == 'right-acromioclavicular-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-temporomandibular-joint ${
              joints.find(
                (joint) => joint.option == 'right-temporomandibular-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) => joint.option == 'right-temporomandibular-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-sternoclavicular-joint ${
              joints.find(
                (joint) => joint.option == 'right-sternoclavicular-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) => joint.option == 'right-sternoclavicular-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>

          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-elbow-joint ${
              joints.find((joint) => joint.option == 'left-elbow-joint')
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find((joint) => joint.option == 'left-elbow-joint') &
                updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-glenohumeral-joint ${
              joints.find((joint) => joint.option == 'left-glenohumeral-joint')
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) => joint.option == 'left-glenohumeral-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-acromioclavicular-joint ${
              joints.find(
                (joint) => joint.option == 'left-acromioclavicular-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) => joint.option == 'left-acromioclavicular-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-temporomandibular-joint ${
              joints.find(
                (joint) => joint.option == 'left-temporomandibular-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) => joint.option == 'left-temporomandibular-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-sternoclavicular-joint ${
              joints.find(
                (joint) => joint.option == 'left-sternoclavicular-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) => joint.option == 'left-sternoclavicular-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>

           <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-knee-joint ${
              joints.find(
                (joint) => joint.option == 'right-knee-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) => joint.option == 'right-knee-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>

           <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-knee-joint ${
              joints.find(
                (joint) => joint.option == 'left-knee-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) => joint.option == 'left-knee-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
        </v-img>
      </div>
      <div class="img-container large-left-hand-container d-inline-block">
        <v-img src="/imgs/large-left-hand.svg">
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-hand-thumb-metacarpophalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option == 'left-hand-thumb-metacarpophalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option == 'left-hand-thumb-metacarpophalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-hand-thumb-interphalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option == 'left-hand-thumb-interphalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option == 'left-hand-thumb-interphalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-hand-index-metacarpophalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option == 'left-hand-index-metacarpophalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option == 'left-hand-index-metacarpophalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-hand-index-proximal-interphalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option ==
                  'left-hand-index-proximal-interphalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option ==
                    'left-hand-index-proximal-interphalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-hand-index-distal-interphalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option == 'left-hand-index-distal-interphalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option ==
                    'left-hand-index-distal-interphalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-hand-middle-metacarpophalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option == 'left-hand-middle-metacarpophalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option == 'left-hand-middle-metacarpophalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-hand-middle-proximal-interphalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option ==
                  'left-hand-middle-proximal-interphalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option ==
                    'left-hand-middle-proximal-interphalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-hand-middle-distal-interphalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option ==
                  'left-hand-middle-distal-interphalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option ==
                    'left-hand-middle-distal-interphalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-hand-ring-metacarpophalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option == 'left-hand-ring-metacarpophalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option == 'left-hand-ring-metacarpophalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-hand-ring-proximal-interphalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option ==
                  'left-hand-ring-proximal-interphalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option ==
                    'left-hand-ring-proximal-interphalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-hand-ring-distal-interphalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option == 'left-hand-ring-distal-interphalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option ==
                    'left-hand-ring-distal-interphalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-hand-pinky-metacarpophalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option == 'left-hand-pinky-metacarpophalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option == 'left-hand-pinky-metacarpophalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-hand-pinky-proximal-interphalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option ==
                  'left-hand-pinky-proximal-interphalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option ==
                    'left-hand-pinky-proximal-interphalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-hand-pinky-distal-interphalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option == 'left-hand-pinky-distal-interphalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option ==
                    'left-hand-pinky-distal-interphalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-wrist-joint ${
              joints.find((joint) => joint.option == 'left-wrist-joint')
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find((joint) => joint.option == 'left-wrist-joint') &
                updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
        </v-img>
        <span>{{$t("left_hand")}}</span>
      </div>
    </v-col>
    <v-col cols="12" class="d-flex align-end justify-center pb-6">
      <div class="img-container right-foot-container d-inline-block mr-16 pr-5">
        <v-img src="/imgs/right-foot.svg">
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-anckle-joint ${
              joints.find((joint) => joint.option == 'right-anckle-joint')
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            width="29.94"
            height="29.94"
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find((joint) => joint.option == 'right-anckle-joint') &
                updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-talonavicular-joint ${
              joints.find(
                (joint) => joint.option == 'right-talonavicular-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            width="29.94"
            height="29.94"
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) => joint.option == 'right-talonavicular-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-foot-pinky-metatarsophalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option == 'right-foot-pinky-metatarsophalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            width="20.13"
            height="20.13"
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option == 'right-foot-pinky-metatarsophalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-foot-pinky-proximal-interphalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option ==
                  'right-foot-pinky-proximal-interphalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            width="20.13"
            height="20.13"
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option ==
                    'right-foot-pinky-proximal-interphalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-foot-ring-metatarsophalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option == 'right-foot-ring-metatarsophalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            width="20.13"
            height="20.13"
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option == 'right-foot-ring-metatarsophalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-foot-ring-proximal-interphalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option ==
                  'right-foot-ring-proximal-interphalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            width="20.13"
            height="20.13"
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option ==
                    'right-foot-ring-proximal-interphalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-foot-middle-metatarsophalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option == 'right-foot-middle-metatarsophalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            width="20.13"
            height="20.13"
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option ==
                    'right-foot-middle-metatarsophalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-hand-middle-proximal-interphalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option ==
                  'right-hand-middle-proximal-interphalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            width="20.13"
            height="20.13"
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option ==
                    'right-hand-middle-proximal-interphalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-foot-index-metatarsophalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option == 'right-foot-index-metatarsophalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            width="25"
            height="25"
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option == 'right-foot-index-metatarsophalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-foot-index-proximal-interphalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option ==
                  'right-foot-index-proximal-interphalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            width="25"
            height="25"
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option ==
                    'right-foot-index-proximal-interphalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-foot-thumb-metatarsophalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option == 'right-foot-thumb-metatarsophalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            width="25"
            height="25"
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option == 'right-foot-thumb-metatarsophalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-foot-thumb-proximal-interphalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option ==
                  'right-foot-thumb-proximal-interphalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            width="25"
            height="25"
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option ==
                    'right-foot-thumb-proximal-interphalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
        </v-img>
        <span>{{$t("right_foot")}}</span>
      </div>
      <div class="img-container left-foot-container d-inline-block ml-16 pl-5">
        <v-img src="/imgs/left-foot.svg">
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-anckle-joint ${
              joints.find((joint) => joint.option == 'left-anckle-joint')
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            width="29.94"
            height="29.94"
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find((joint) => joint.option == 'left-anckle-joint') &
                updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-talonavicular-joint ${
              joints.find((joint) => joint.option == 'left-talonavicular-joint')
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            width="29.94"
            height="29.94"
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) => joint.option == 'left-talonavicular-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-foot-pinky-metatarsophalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option == 'left-foot-pinky-metatarsophalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            width="20.13"
            height="20.13"
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option == 'left-foot-pinky-metatarsophalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-foot-pinky-proximal-interphalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option ==
                  'left-foot-pinky-proximal-interphalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            width="20.13"
            height="20.13"
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option ==
                    'left-foot-pinky-proximal-interphalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-foot-ring-metatarsophalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option == 'left-foot-ring-metatarsophalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            width="20.13"
            height="20.13"
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option == 'left-foot-ring-metatarsophalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-foot-ring-proximal-interphalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option ==
                  'left-foot-ring-proximal-interphalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            width="20.13"
            height="20.13"
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option ==
                    'left-foot-ring-proximal-interphalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-foot-middle-metatarsophalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option == 'left-foot-middle-metatarsophalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            width="20.13"
            height="20.13"
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option == 'left-foot-middle-metatarsophalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-foot-middle-proximal-interphalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option ==
                  'left-foot-middle-proximal-interphalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            width="20.13"
            height="20.13"
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option ==
                    'left-foot-middle-proximal-interphalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-foot-index-metatarsophalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option == 'left-foot-index-metatarsophalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            width="25"
            height="25"
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option == 'left-foot-index-metatarsophalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-foot-index-proximal-interphalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option ==
                  'left-foot-index-proximal-interphalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            width="25"
            height="25"
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option ==
                    'left-foot-index-proximal-interphalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-foot-thumb-metatarsophalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option == 'left-foot-thumb-metatarsophalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            width="25"
            height="25"
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option == 'left-foot-thumb-metatarsophalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-foot-thumb-proximal-interphalangeal-joint ${
              joints.find(
                (joint) =>
                  joint.option ==
                  'left-foot-thumb-proximal-interphalangeal-joint'
              )
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            width="25"
            height="25"
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                joints.find(
                  (joint) =>
                    joint.option ==
                    'left-foot-thumb-proximal-interphalangeal-joint'
                ) & updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
        </v-img>
        <span>{{$t("left_foot")}}</span>
      </div>
    </v-col>
    <v-col cols="12" class="d-flex justify-center main-title pt-0 pb-0">
      <p class="mb-5">{{ title }}</p>
    </v-col>
    <v-col cols="12" class="d-flex justify-center main-title pt-0 pb-0">
      <p class="mb-0 number">{{ joints.length }}</p>
    </v-col>
  </v-row>
</template>


<script>
export default {
  name: "BodyMap",

  props: {
    title: {
      type: String,
      default: "",
    },
    joints: {
      type: Array,
      default: () => [],
    },
    updateMood: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    injuredJoints: function () {
      let injuredJoints = 0;
      for (const property in this.data) {
        if (this.data[property] == true) {
          injuredJoints++;
        }
      }

      return injuredJoints;
    },
  },
};
</script>

<style lang="scss" scoped src="@/assets/sass/components/views/dashboard/members/id/body-map.scss">
</style>
