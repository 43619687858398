export default {
    methods: {
        getProgressBarValue(index) {
          if (this.data.history[index - 1]) {
            return (
              (this.data.history[index - 1].score / this.data.history[index - 1].max_score) *
              100
            );
          }
    
          return 0;
        },
      },
}