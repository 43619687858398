import Default from "@/layouts/Default.vue";

let AdminRoutes = [
  {
    path: "admin-users",
    name: "admin-users",
    component: () =>
      import(/* webpackChunkName: "admin-users" */ "@/views/admin/users.vue"),
    meta: {
      layout: Default,
      auth: true,
      title: "admin",
    },
  },
];

export default AdminRoutes;
