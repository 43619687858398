<template>
  <v-container class="h-100 terms">
    <v-row class="h-100 align-center" justify="center">
      <v-card elevation="0" class="justify-center col col-9">
        <v-card-title class="justify-center">
          <img
            alt="logo"
            :src="
              $store.getters['auth/user']
                ? $store.getters['auth/user'].theme_config.header_logo
                : ''
            "
            class="logo"
          />
        </v-card-title>

        <!-- class="h-100 d-flex align-center justify-center flex-column" -->
        <div class="pt-5" v-if="err">
          <div class="v-input__control d-flex justify-center alert-container">
            <v-alert text outlined class="red-alert">
              {{ $t("something_went_wrong") }}
            </v-alert>
          </div>
        </div>

        <v-card
          :class="`terms-link mb-5 ${err ? 'mt-2' : 'mt-0'}`"
          elevation="0"
        >
          <h1 class="main-title">Your data and privacy</h1>
          <p class="headline mb-1">
            In compliance with data protection legislation, we inform you of the following regarding user’s personal data in general and yours, as a Healthcare Professional, in particular:
          </p>
          
          <h3 class="headline">Responsible</h3>
          <p>ADHERA HEALTH, S.L. (B91968685).</p>

          <h3 class="headline">Purpose</h3>
          <p>
            The requested data are necessary to perform with your collaboration the service of providing recommendations of trained health professionals through the application. Likewise, the data processing gathered through application performance may be used for profiling purposes in case of user’s explicit consent.
          </p>
          
          <h3 class="headline">Legitimation</h3>
          <p>Your consent and performance of the contractual provision.</p>
          
          <h3 class="headline">Recipients</h3>
          <p>
            User’s data will only be communicated to the suppliers with whom we work and the health professionals, as you in this case, with whom we collaborate to offer you the service or in case of legal and/or judicial obligation. No data will be transferred to countries outside the European Union unless they are GDPR compliant.
          </p>
          
          <h3 class="headline">Conservation</h3>
          <p>
            We will keep user’s data in general (and your as Healtchare Professional in particular) for as long as your account is active as a customer of Adhera unless there is a specific legal obligation to keep it.
          </p>

          <h3 class="headline">Exercise of rights</h3>
          <p>
            You have the right to revoke your consent, as well as to access, rectify, delete, limit, oppose, or request the transferability of your personal data by sending an email to privacy@adherahealth.com or by a letter in writing to Avda. de los Descubrimientos s/n, Edificio Circuito II, Planta Primera, Módulo 37 B, C.P. 41927, Mairena del Aljarafe, Seville (Spain), indicating as reference "Exercise of Rights". You can also file a complaint with the Spanish Data Protection Agency at: 
            <a href="https://www.aepd.es" class="bold">www.aepd.es</a>
          </p>
        </v-card>

        <v-divider></v-divider>

        <v-flex class="justify-center">
          <v-checkbox
            name="accept_terms"
            id="accept_terms"
            :true-value="true"
            :false-value="false"
            v-model="terms"
          >
            <template v-slot:append>
              I have read the
              <a href="http://adheraservices.com/products/legal/longcovid/legal_terms_es.html" class="bold">Terms of Use</a> and the
              <a href="http://adheraservices.com/products/legal/longcovid/privacy_policy_es.html" class="bold">Privacy and Cookie Policy</a>
              and agree that my personal data may be processed for the purpose
              of providing telemedicine as well as for scientific research or
              statistical purposes.
            </template>
          </v-checkbox>

          <div class="v-input__control d-flex flex-row proceed_btn mt-8">
            <v-btn :disabled="!valid" @click="submit">
              {{ $t("continue") }}
            </v-btn>
          </div>
        </v-flex>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      err: null,
      terms: false,
      valid: false,
    };
  },

  beforeCreate() {
    // if (this?.$store?.getters["auth/user"]?.is_terms_conditions) {
    //   return this.$router
    //     .push({
    //       name: "members-list",
    //       params: {
    //         lang: this.$i18n.locale,
    //       },
    //     })
    //     .catch(() => {});
    // }
  },

  mounted() {
    this.$store.dispatch("logActivity", {
      tag: "term_conditions",
      type: 1,
    });
  },

  destroyed() {
    this.$store.dispatch("logActivity", {
      tag: "term_conditions",
      type: 2,
    });
  },

  watch: {
    terms: function(val) {
      return (this.valid = val == true ? true : false);
    },
  },

  methods: {
    async submit() {
      let res = await axios.post(
        "/dashboard/users/update-terms-conditions-attribute",
        {
          is_terms_conditions: this.terms,
        }
      );

      if (res.status == 200) {
        // refetching user
        await this.$store.dispatch(
          "auth/attempt",
          this.$store.getters["auth/authenticated"].mc_access_token
        );

        if (this.$store.getters["auth/user"].is_terms_conditions) {
          return this.$router.push({ name: "members-list" });
        }
      }

      this.err = true;
    },
  },
};
</script>

<style lang="scss" src="@/assets/sass/views/terms.scss"></style>
