import axios from "axios";

export default {
	namespaced: true,

	state: {
		loading: false,
		unreadMessagesCount: 0,
		chatWithMessages: {},
		messages: [],
	},

	getters: {
		unreadMessagesCount(state) {
			return state.unreadMessagesCount;
		},
		chatWithMessages(state) {
			return state.chatWithMessages;
		},
		messages(state) {
			return state.messages;
		},
	},

	mutations: {
		SET_UNREAD_MESSAGES_COUNT(state, unreadMessagesCount) {
			state.unreadMessagesCount = unreadMessagesCount;
		},
		SET_CHAT_WITH_MESSAGES(state, chatWithMessages) {
			state.chatWithMessages = chatWithMessages;
		},
		APPEND_TO_CHAT_WITH_MESSAGES(state, messages) {
			state.messages = messages;
		},
	},

	actions: {
		getChats({ state, commit }) {
			return axios
				.get("dashboard/messenger/chats")
				.then(({ data: { data } }) => data);
		},
		getUnreadMessagesCount({ state, commit }) {
			return axios
				.get("dashboard/messenger/chats/unread-messages")
				.then(({ data }) => commit("SET_UNREAD_MESSAGES_COUNT", data.data));
		},
		getChatMessages({ dispatch, commit }, chat) {
			return axios
				.get(`dashboard/messenger/chats/${chat.id}`)
				.then(async ({ data }) => {
					data.data = await dispatch("rearrangeMessages", data.data);
					commit("APPEND_TO_CHAT_WITH_MESSAGES", data);
				})
				.catch((err) => console.log(err));
		},
		async appendToChatMessages({ state, commit, dispatch }, newMessages) {
			Object.entries(newMessages.data).forEach(
				([messageGroupDate, messageGroup]) => {
					if (Object.keys(state.messages.data).includes(messageGroupDate)) {
						state.messages.data[messageGroupDate] = Object.values(
							messageGroup
						).concat(Object.values(state.messages.data[messageGroupDate]));
					} else {
						state.messages.data[messageGroupDate] = messageGroup;
					}
				}
			);

			newMessages.data = await dispatch(
				"rearrangeMessages",
				state.messages.data
			);
			commit("APPEND_TO_CHAT_WITH_MESSAGES", newMessages);
		},
		rearrangeMessages(_, messages) {
			return Object.keys(messages)
				.sort()
				.reduce((accumulator, key) => {
					accumulator[key] = messages[key];

					return accumulator;
				}, {});
		},
	},
};
