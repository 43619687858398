<template>
  <v-textarea
    v-model="message"
    outlined
    filled
    single-line
    hide-details
    append-icon="mdi-send"
    no-resize
    :rows="getNumberOfRows()"
  >
    <template v-slot:append>
      <emoji-picker @emoji="append" :search="searchEmoji">
        <button
          class="emoji-invoker"
          slot="emoji-invoker"
          slot-scope="{ events: { click: clickEvent } }"
          @click.stop="clickEvent"
        >
          <svg
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 fill-current text-grey"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"
            />
          </svg>
        </button>
        <div slot="emoji-picker" slot-scope="{ emojis, insert }">
          <div class="emoji-picker">
            <div class="emoji-picker__search">
              <input type="text" v-model="searchEmoji" v-focus />
            </div>
            <div>
              <div v-for="(emojiGroup, category) in emojis" :key="category">
                <h5>{{ category }}</h5>
                <div class="emojis">
                  <span
                    v-for="(emoji, emojiName) in emojiGroup"
                    :key="emojiName"
                    @click="insert(emoji)"
                    :title="emojiName"
                    >{{ emoji }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </emoji-picker>

      <button
        type="button"
        aria-label="append icon"
        class="v-icon notranslate v-icon--link mdi mdi-send theme--light"
        @click="$emit('sendMessage', message); (message = '');"
      ></button>
    </template>
  </v-textarea>
</template>

<script>
import { EmojiPicker } from "vue-emoji-picker";

export default {
  name: "ChatInput",

  data() {
    return {
      message: "",
      searchEmoji: "",
    };
  },

  components: {
    EmojiPicker,
  },

  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },

  methods: {
    append(emoji) {
      this.message += emoji;
    },
    getNumberOfRows() {
      if (typeof this.message !== 'string') {
        throw new TypeError("The value of the message is not a string", "ChatInput.vue", 98);
      }
      const rows = this.message.replace(/\r\n/g, '\n').split('\n').length;
      /*
        The default number of rows of the element v-textarea is 5, so the
        minimum isn't set to 5 the box shrinks instead of keeping the same size.
      */
      return rows > 5 ? rows : 5;
    },
  },
};
</script>
