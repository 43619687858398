import store from "../store";
import router from "../router";
import i18n from "@/plugins/i18n";

export default function termsAndConditionsUserMiddleware(to) {
  // if passed 2fa
  if (
    store.getters["auth/user"]?.enable_two_factor_auth == false ||
    (store.getters["auth/user"]?.enable_two_factor_auth &&
    store.getters["auth/user"]?.is_two_factor_auth)
    ) {
     if (
      store.getters["auth/user"]?.is_terms_conditions == false &&
      to.name !== "terms"
      ) {
      return router.push({
        params: {
          lang: i18n.locale,
        },
        name: "terms",
      });
    }
  }
}
