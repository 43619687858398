<template>
  <v-col cols="12" lg="6">
    <cards-container
      class="progress-bars-card"
      :title="data.title"
      :date="data.last_report_date"
      :redirectionLink="redirectionLink"
      :isHighlighted="data.is_highlighted"
    >
      <template v-slot:body>
        <v-row class="body">
          <v-col cols="7" class="pt-0 pb-0 pl-0 m-auto">
            <v-list-item-title class="mb-2">
              <span class="text-h5">{{ $t("score") }} </span>
              <span class="text-h4">{{ data.score.last_score }}</span>
              <small> {{ $t("of") }} {{ data.score.max_score }}</small>
            </v-list-item-title>

            <v-list-item-subtitle>
              <span
                :class="`incline-decline ${
                  data.trend.value > 0 ? 'up' : 'down'
                }`"
              >
                <span
                  :class="`icomoon  ${
                    data.trend.value > 0 ? 'icon-up' : 'icon-down'
                  }`"
                ></span>
                {{ Math.abs(data.trend.value) }}
              </span>
              {{ $t("since_last_report") }}
            </v-list-item-subtitle>
          </v-col>

          <v-col cols="5" class="pt-0 pb-0 pr-0">
            <div class="progress-bars-container d-flex">
              <v-progress-linear
                v-for="i in 6"
                :key="i"
                :value="getProgressBarValue(i)"
                :color="
                  $store.getters['auth/user']
                    ? $store.getters['auth/user'].theme_config
                        .progress_bar_primary_color
                    : ''
                "
                :style="`transform: rotate(270deg) translate(35px, ${
                  i * 30 - 45
                }px); margin-bottom:${i * 10};`"
                background-color="#DBDCDD"
              ></v-progress-linear>
            </div>
          </v-col>
        </v-row>
      </template> </cards-container
  ></v-col>
</template>

<script>
import CardsContainer from "./CardsContainer.vue";
import progressBarGraphMixin from "@/mixins/progressBarGraph.js";

export default {
  name: "SummaryCardSingleGraph",

  props: {
    redirectionLink: {
      type: String,
      default: "",
    },
    data: {
      type: Object,
      default: () => {},
    },
  },

  components: {
    CardsContainer,
  },

  mixins: [progressBarGraphMixin],
};
</script>

<style lang="scss" src="@/assets/sass/components/views/dashboard/members/id/partial/cards/single-progressbar-set-card.scss">
</style>