/* eslint-disable no-unused-vars */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import middlewares from '@/middlewares/index.js'
import plugins from '@/plugins/index.js'
import './sentry.js'
import '@/assets/sass/style.scss';
require('@/store/subscriber')

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    $eval: param => eval('this.' + param)
  }
});

store.dispatch('auth/attempt', localStorage.getItem('mc_access_token')).then(() => {
  new Vue({
    router,
    store,
    ...plugins,
    render: h => h(App)
  }).$mount('#app')
});

