import Default from '@/layouts/Default.vue';
import Chat from '@/layouts/Chat.vue';
import i18n from '@/plugins/i18n.js';

let DashboardRoutes = [
    {
        path: 'members',
        name: 'members-list',
        component: () => import(/* webpackChunkName: "members-list" */ "@/views/dashboard/members/Index.vue"),
        meta: {
            layout: Default,
            auth: true,
            title: 'members_list',
            breadcrumbs: [
                {
                    first: true,
                    text: 'members',
                    translatedText: null,
                    disabled: false,
                    href: `/${i18n.locale}/members`,
                },
                {
                    text: 'all_members',
                    translatedText: null,
                    disabled: true
                }
            ],
        }
    },
    {
        path: "members/:id",
        name: "members-profile",
        component: () => import(/* webpackChunkName: "members-profile" */ "@/views/dashboard/members/_id/index.vue"),
        meta: {
            layout: Default,
            auth: true,
            title: 'member_profile',
            breadcrumbs: [
                {
                    first: true,
                    text: 'members',
                    translatedText: null,
                    disabled: false,
                    href: `/${i18n.locale}/members`,
                },
                {
                    text: '',
                    disabled: true
                },
            ],
        }
    },
    {
        path: "members/:id/detailed-view",
        name: "detailed-view",
        component: () => import(/* webpackChunkName: "id/detailed-view" */ "@/views/dashboard/members/_id/detailed-view.vue"),
        meta: {
            layout: Default,
            auth: true,
            title: 'member_detailed_view',
            breadcrumbs: [
                {
                    first: true,
                    text: 'members',
                    disabled: false,
                    href: `/${i18n.locale}/members`,
                },
                {
                    text: '',
                    disabled: false,
                    href: ``,
                },
                {
                    text: 'detailed_view',
                    translatedText: null,
                    disabled: true,
                },
            ],
        }
    },
    {
        path: "members/:id/questionnaires/:questionnaire",
        name: "questionnaire",
        component: () => import(/* webpackChunkName: "id/questionnaire" */ "@/views/dashboard/members/_id/questionnaires/_id.vue"),
        meta: {
            layout: Default,
            auth: true,
            title: 'member_questionnaire_view',
            breadcrumbs: [
                {
                    first: true,
                    text: 'members',
                    disabled: false,
                    href: `/${i18n.locale}/members`,
                },
                {
                    text: '',
                    disabled: false,
                    href: ``,
                },
                {
                    text: '',
                    disabled: true,
                },
            ],
        }
    },
    {
        path: "messages",
        name: "messages",
        component: () => import(/* webpackChunkName: "messages" */ "@/views/dashboard/messages/index.vue"),
        meta: {
          layout: Chat,
          auth: true,
          title: "chat",
          breadcrumbs: [
            {
              first: true,
              text: "messages",
              disabled: false,
              href: `/${i18n.locale}/messages`,
            },
          ],
        },
    },
];

export default DashboardRoutes;
