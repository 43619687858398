<template>
    <v-container class="chat pr-0 pt-0">
        <v-row>
            <!-- Header -->
            <v-col cols="12" class="pr-0 pb-7">
                <div class="chat-header d-flex p-0">
                    <v-skeleton-loader
                        type="list-item-avatar"
                        v-if="loading"
                        width="70%"
                    ></v-skeleton-loader>

                    <template v-else>
                        <v-avatar size="48" class="mr-6">
                            <img :src="getDefaultAvatar(chat)" v-if="chat" alt="avatar" />
                        </v-avatar>

                        <div class="user-container d-flex mt-1" v-if="chat">
                            <p class="name text-uppercase mb-1">
                                {{
                                    name(
                                        chat.patient_first_name,
                                        chat.patient_last_name,
                                        chat.patient_email
                                    )
                                }}
                            </p>
                            <span class="user-info">
                                {{ chat.patient_id }}
                                {{ chat.patient_first_name ? `// ${chat.patient_email}` : "" }}
                                {{ chat.patient_gender == 1 ? `// ${$t("male")}` : chat.patient_gender == 2 ? `// ${$t("female")}` : "" }}
                            </span>
                        </div>
                    </template>
                </div>
            </v-col>

            <!-- Body  -->

            <!-- skeleton loader  -->
            <v-col cols="12" v-if="loading">
                <v-skeleton-loader type="paragraph" width="70%"></v-skeleton-loader>
            </v-col>

            <template v-else>
                <!-- no available chats  -->
                <p
                    class="
                w-100
                text-center text-capitalize
                mt-10
                title
                no-data-available
            "
                    v-if="userHasNoChats"
                >
                    {{ $t("no_data_available") }}
                </p>

                <!-- messages box -->
                <template v-else>
                    <v-col
                        cols="12"
                        ref="chat"
                        @click="$emit('readChatMessages', chat.id)"
                        @scroll="onScroll"
                        class="chat-body pr-5 "
                    >
                        <v-container class="">
                            <v-row
                                v-for="(messageGroup, messageGroupDate) in messages.data"
                                :key="messageGroupDate"
                                class="chat-window"
                            >
                                <v-col cols="12" class="message-group-date pt-0 pb-5 text-center">
                                    {{
                                        dateFns.isToday(new Date(messageGroupDate))
                                            ? $t("today")
                                            : messageGroup[0].formated_created_at
                                    }}
                                </v-col>

                                <v-col cols="12" class="p-0 message-group">
                                    <div
                                        v-for="(message, index) in messageGroup"
                                        :key="message.id"
                                        :class="
                                            `message-container ${message.sender_type}-message-container mb-4 d-flex`
                                        "
                                    >
                                        <div
                                            class="sender-container d-flex align-center w-100"
                                            v-if="
                                                !messageGroup[index - 1] ||
                                                    messageGroup[index - 1]['user_id'] !==
                                                        messageGroup[index]['user_id']
                                            "
                                        >
                                            <v-avatar
                                                size="48"
                                                class="mb-2"
                                                v-if="message.sender_type != 'system'"
                                            >
                                                <img
                                                    :src="
                                                        message.user_avatar ||
                                                            getDefaultAvatar(chat)
                                                    "
                                                    alt="avatar"
                                                />
                                            </v-avatar>

                                            <p class="sender-name ml-3 mr-3">
                                                {{
                                                    name(
                                                        message.user_first_name,
                                                        message.user_last_name,
                                                        message.user_email
                                                    )
                                                }}
                                            </p>
                                        </div>

                                        <v-banner outlined :class="`mb-2 message`" :ref="`message-${message.id}`" :style="`white-space: pre-line;`">
                                            {{ message.message }}

                                            <template v-slot:actions>
                                                <span class="message-time">
                                                    {{
                                                        dateFns.format(
                                                            new Date(message.created_at),
                                                            "HH:mm"
                                                        )
                                                    }}
                                                </span>
                                            </template>
                                        </v-banner>
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row cols="12" class="p-0" ref="messagesEnd"></v-row>
                        </v-container>
                    </v-col>

                    <v-col
                        cols="12"
                        class="chat-send-message-box pr-1 pt-3"
                        @click="$emit('readChatMessages', chat.id)"
                    >
                        <ChatInput @sendMessage="sendMessage" />
                    </v-col>
                </template>
            </template>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import dataFnsMixin from "@/mixins/date-fns.js";
import chatMixin from "@/mixins/chat.js";

import ChatInput from "./ChatInput.vue";

export default {
    name: "MessagesContainer",

    props: {
        chat: {
            type: Object,
            default: () => {},
        },
        messageAdded: {
            type: Boolean,
            default: false,
        },
        userHasNoChats: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            messagesMid: null,
        };
    },

    computed: {
        ...mapGetters({
            messages: "messages/messages",
        }),

        name() {
            return (first_name, last_name, email) =>
                first_name?.length > 1 ? `${first_name} ${last_name ?? ""}` : email;
        },
        loading() {
            if (this.userHasNoChats) {
                return false;
            }

            if (!this.messages || this.messages.length == 0) {
                return true;
            }

            return false;
        },
    },

    watch: {
        async chat(newVal, oldVal) {
            if (newVal) {
                await this.getChatMessages(newVal);
                this.scrollToUnreadMsg();
            }
        },
        messageAdded(newVal) {
            if (newVal == true && this.chat) {
                this.getChatMessages(this.chat);
            }
        },
        messages: {
            handler(newVal, oldVal) {
                if (newVal) {
                    this.$emit("chatFetched");
                }
            },
            immediate: true,
        },
    },

    mixins: [dataFnsMixin, chatMixin],

    components: {
        ChatInput,
    },

    methods: {
        ...mapActions({
            getChatMessages: "messages/getChatMessages",
        }),
        sendMessage(message) {
            this.$axios
                .post(`dashboard/messenger/chats/messages`, {
                    patient_id: this.chat.patient_id,
                    message,
                })
                .then((res) => {
                    this.getChatMessages(this.chat);
                    this.$emit("readChatMessages", this.chat.id);
                })
                .catch((err) => console.log(err));
        },
        serachMessages(e) {
            this.$store.dispatch("members/searchMembers", e);
        },
        onScroll({ target: { scrollTop, scrollHeight } }) {
            if (scrollTop == 0) {
                this.$axios.get(this.messages.next_page_url).then(({ data }) => {
                    this.messagesMid = scrollHeight;
                    this.$store.dispatch("messages/appendToChatMessages", data);
                });
            }
        },
        scrollToUnreadMsg() {
            let lastSeenMessage = null;

            if(this.chat.unread_messages_count > 0) {
                const flattenMessags =Object.values(this.messages.data).flat(); 
                lastSeenMessage = flattenMessags.find((msg) => this.dateFns.isAfter(new Date(msg.created_at), new Date(this.chat.last_seen)));
            }

            this.$refs.chat.scrollTop =
                this.chat.unread_messages_count > 0 ? this.$refs[`message-${lastSeenMessage?.id}`][0].$el.scrollHeight : this.$refs.chat.scrollHeight;
        },
    },
};
</script>

<style lang="scss" src="@/assets/sass/components/messages/messages-container.scss"></style>
