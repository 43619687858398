<template>
    <div class="admin-title d-flex mt-8 mb-16">
        <v-hover v-slot="{ hover }">
            <v-avatar size="200" :class="{ 'on-hover': hover }" @click="uploadImageModal = true">
                <img
                    class="image w-100"
                    :src="admin.avatar || inActiveAvatar"
                    :alt="admin.full_name"
                />
                <v-fade-transition>
                    <v-overlay v-if="hover" absolute color="#000000" opacity="0.69">
                        <v-icon xlarge dark>
                            mdi-pencil
                        </v-icon>
                    </v-overlay>
                </v-fade-transition>
            </v-avatar>
        </v-hover>

        <!-- <initials-avatar /> -->
        <!-- <p class="title mb-0"> -->
        <!-- {{ $t($route.meta.title) }} -->
        <!-- </p> -->

        <!-- upload image modal  -->
        <v-dialog v-model="uploadImageModal" width="688">
            <v-card class="">
                <v-card-title class="d-flex flex-column pb-0">
                    <v-icon class="ml-auto" @click="uploadImageModal = false" large> mdi-close-circle-outline </v-icon>
                </v-card-title>

                <v-card-text class="d-flex align-center justify-center">
                    <v-avatar
                        size="200"
                        @mouseover="hoverAvatar = true"
                        @mouseout="hoverAvatar = false"
                    >
                        <v-img
                            class=""
                            :src="previewImageURL || admin.avatar || inActiveAvatar"
                            :alt="admin.full_name"
                        >
                        </v-img>
                        <v-fade-transition>
                            <v-overlay
                                :class="{ 'd-flex': hoverAvatar }"
                                absolute
                                color="#000000"
                                opacity="0.69"
                            >
                                <v-file-input
                                    hide-input
                                    prepend-icon="mdi-pencil"
                                    :rules="fileRules"
                                    v-model="uploadedImage"
                                    @change="previewImage"
                                />
                            </v-overlay>
                        </v-fade-transition>
                    </v-avatar>

                    <v-btn
                        hide-details
                        class="
                                ma-auto
                                text-uppercase
                                bold
                                d-flex
                                align-center
                                text-center
                                float-right
                                bg-theme-primary
                                save-btn
                            "
                        height="46"
                        width="188"
                        :loading="uploadImageLoading"
                        @click="uploadImage"
                    >
                        {{ $t("save") }}
                    </v-btn>
                </v-card-text>
                <v-card-actions>
                    Disclosure text//Privacy Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                    ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                    commodo consequat.
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import InitialsAvatar from "../../InitialsAvatar.vue";
import chatMixin from "@/mixins/chat.js";

export default {
    name: "AdminTitle",

    props: {
        title: {
            type: String,
            default: "",
        },
    },

    data() {
        return {
            hoverAvatar: false,
            uploadImageModal: false,
            uploadImageLoading: false,
            uploadedImage: null,
            previewImageURL: null,
            fileRules: [
                (value) => !value || value.size < 2048 || "Avatar size should be less than 2 MB!",
            ],
        };
    },

    computed: {
        admin() {
            return this.$store.getters["auth/user"];
        },
    },

    watch: {
        uploadImageModal(val) {
            if (!val) {
                this.previewImageURL = null;
            }
        },
    },

    components: {
        InitialsAvatar,
    },

    mixins: [chatMixin],

    methods: {
        previewImage(event) {
            this.previewImageURL = this.uploadedImage ? URL.createObjectURL(this.uploadedImage) : null;
        },
        uploadImage() {
            this.uploadImageLoading = true;

            let formData = new FormData();
            formData.append("avatar", this.uploadedImage);

            this.$axios
                .post("profile/avatar", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {
                    this.uploadImageLoading = this.uploadImageModal = false;
                    this.admin.avatar = res.data.url;
                })
                .catch((err) => {
                    this.uploadImageLoading = false;
                    this.$toasted.error(err?.response?.data?.errors?.avatar[0]);
                });
        },
    },
};
</script>

<style lang="scss" src="@/assets/sass/components/views/admin/admin-title.scss"></style>
