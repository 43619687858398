import * as dateFns from 'date-fns'
import { es } from 'date-fns/locale'
import { enUS } from 'date-fns/locale'

var dataFnsMixin = {
    data()
    {
        return {
            dateFns,
            dateFnsLocales: {
                es,
                enUS
            }
        }
    },
    
}

export default dataFnsMixin;