import router from '../router'
import routeLangMiddleware from './routeLangMiddleware'
import pageTitleMiddleware from './pageTitleMiddleware'
import authenticatedUserMiddleware from './authenticatedUserMiddleware'
import termsAndConditionsUserMiddleware from './termsAndConditionsUserMiddleware'
import twoFactorAuthMiddleware from './twoFactorAuthMiddleware'

router.beforeEach((to, from, next) =>
{
  routeLangMiddleware(to)
  pageTitleMiddleware(to)

  if (to.meta?.auth)
  {
    authenticatedUserMiddleware(to)
    twoFactorAuthMiddleware(to)
    termsAndConditionsUserMiddleware(to)
  }

  next();
});