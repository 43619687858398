<template>
  <v-row class="esas-questionnaire-panel reported_questionnaires">
    <v-col cols="12" class="sub-title ml-2">
      {{ `${$t("reported")}  ${$t("questionnaires")}` }}
    </v-col>
    <v-col cols="12">
      <v-expansion-panels class="pr-1" v-model="openPanel">
        <v-expansion-panel
          v-for="(questionnaire, i) in questionnaires"
          :key="i"
        >
          <v-expansion-panel-header
            class="pa-6"
            :expand-icon="openPanel == i ? 'mdi-minus' : 'mdi-plus'"
          >
            <span class="d-flex-inline pt-2 pb-2 date">
              {{ questionnaire.date }}
            </span>
            <span class="float-right flex-2 score" v-if="openPanel != i">
              {{ $t("score") }} <strong>{{ questionnaire.score }}</strong>
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <h3 class="d-inline-block">{{ $t("patient_responses") }}</h3>
            <h4 class="float-right d-inline-block edit-response">
              <v-icon>icon-edit</v-icon> {{ $t("edit_responses") }}
            </h4>

            <v-simple-table class="mt-8">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th></th>
                    <th class="text-center text-uppercase font-weight-bold">
                      0
                    </th>
                    <th class="text-center text-uppercase font-weight-bold">
                      1
                    </th>
                    <th class="text-center text-uppercase font-weight-bold">
                      2
                    </th>
                    <th class="text-center text-uppercase font-weight-bold">
                      3
                    </th>
                    <th class="text-center text-uppercase font-weight-bold">
                      4
                    </th>
                    <th class="text-center text-uppercase font-weight-bold">
                      5
                    </th>
                    <th class="text-center text-uppercase font-weight-bold">
                      6
                    </th>
                    <th class="text-center text-uppercase font-weight-bold">
                      7
                    </th>
                    <th class="text-center text-uppercase font-weight-bold">
                      8
                    </th>
                    <th class="text-center text-uppercase font-weight-bold">
                      9
                    </th>
                    <th class="text-center text-uppercase font-weight-bold">
                      10
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in questions" :key="i">
                    <td>
                      <span>{{ item.minText }}</span>
                    </td>
                    <td v-for="(column, index) in 11" :key="index + 1">
                      <div
                        class="circle rounded-circle"
                        :class="{ selected: item.value == index }"
                      >
                        <v-icon v-if="item.value == index">icon-tick</v-icon>
                      </div>
                    </td>
                    <td>
                      <span>{{ item.maxText }}</span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <h3 class="mt-12 mb-9">{{ $t("mark_pictures_where_you_hurt") }}</h3>

            <ESASBodyMap
              title="Swollen joints"
              :joints="joints"
              :updateMood="updateMood"
              class="mb-8"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
import ESASBodyMap from "@/components/views/dashboard/members/id/partial/ESASBodyMap";

export default {
  name: "ESAS",

  props: {
    title: {
      type: String,
      default: "",
    },
    questionnaires: {
      type: Array,
      default: () => [],
    },
    questions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      openPanel: null,
      joints: [
        {
          option: "seventh",
        },
        {
          option: "fifth",
        },
        {
          option: "sixth",
        },
        {
          option: "bottom-first",
        },
        {
          option: "bottom-second",
        },
        {
          option: "bottom-fifth",
        },
        {
          option: "body-center",
        },
      ],
    };
  },

  components: {
    ESASBodyMap,
  },
};
</script>

<style lang="scss" src="@/assets/sass/components/views/dashboard/members/id/partial/questionnaires/components/esas-questionnaire-panels.scss">
</style>