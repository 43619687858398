<template>
  <div class="default h-100 overflow-auto">
    <LifeChampsHeader v-if="isLifeChamps"/>
    <Header v-else/>

    <v-main>
      <v-container fluid class="pb-0">
        <router-view />
      </v-container>
    </v-main>
  </div>
</template>

<script >
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import LifeChampsHeader from '../components/LifeChampsHeader.vue';

export default {
  components: {
    Header,
    Footer,
    LifeChampsHeader,
  },

  computed: {
    isLifeChamps: function () {
      return (
        this.$store.getters["auth/user"]?.theme_config.key == "lifechamps"
      );
    },
  },
};
</script>

<style lang="scss" scoped src="@/assets/sass/layouts/default.scss">
</style>