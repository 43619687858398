import Vue from 'vue';
import Vuetify from 'vuetify';
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/dist/vuetify.min.css';
import es from 'vuetify/lib/locale/es'
import en from 'vuetify/lib/locale/en'
import i18n from './i18n';

Vue.use(Vuetify);

const opts = {
    icons: {
        iconfont: 'mdi',
        sortIcon: "mdi-arrow-up-down"
    },
    lang: {
        locales: { es, en },
        current: i18n.locale.split('_')[0],
    },
};

export default new Vuetify(opts);