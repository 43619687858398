<template>
  <v-row
    class="
      ves-questionnaire-panel
      general-question-panel
      reported_questionnaires
    "
  >
    <v-col cols="12" class="sub-title ml-2">
      {{ `${$t("reported")}  ${$t("questionnaires")}` }}
    </v-col>
    <v-col cols="12">
      <v-expansion-panels class="pr-1" v-model="openPanel">
        <v-expansion-panel
          v-for="(questionnaire, i) in questionnaires"
          :key="i"
        >
          <v-expansion-panel-header
            class="pa-6"
            :expand-icon="openPanel == i ? 'mdi-minus' : 'mdi-plus'"
          >
            <span class="d-flex-inline pt-2 pb-2 date">
              {{ questionnaire.date }}
            </span>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <h3 class="d-inline-block mb-6">{{ $t("patient_responses") }}</h3>
            <h4 class="float-right d-inline-block edit-response">
              <v-icon>icon-edit</v-icon> {{ $t("edit_responses") }}
            </h4>

            <!-- MCQ question  -->
            <div
              v-for="(question, i) in questions"
              class="mcq-container mt-1"
              :key="i"
            >
              <div class="question container-fluid">
                <p>
                  <span>{{ i + 1 }}. </span>{{ question.question }}
                </p>

                <v-row class="answer ma-0">
                  <v-col
                    cols="12"
                    v-for="(answer, i) in question.answers"
                    :key="i + 1"
                  >
                    <div
                      class="circle rounded-circle d-inline-block"
                      :class="{ selected: answer.value }"
                    >
                      <v-icon v-if="answer.value">icon-tick</v-icon>
                    </div>
                    <div class="ml-3 d-inline-block">{{ answer.text }}</div>
                  </v-col>
                </v-row>
                <div class="sub-question-container" v-if="question.subQuestion">
                  <p class="sub-question">{{ question.subQuestion.question }}</p>
                  <h4 class="sub-question-answer">{{ question.subQuestion.answer }}</h4>
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "GeneralQuestionsPanels",

  props: {
    title: {
      type: String,
      default: "",
    },
    questionnaires: {
      type: Array,
      default: () => [],
    },
    questions: {
      type: [Array, Object],
      default: () => [],
    },
  },

  data() {
    return {
      openPanel: null,
    };
  },
};
</script>


<style lang="scss" src="@/assets/sass/components/views/dashboard/members/id/partial/questionnaires/components/general-question-panels.scss">
</style>