<template>
  <v-card
    class="mx-auto cards-container"
    :class="{ highlighted: isHighlighted }"
    width="auto"
    height="100%"
    outlined
  >
    <v-container class="padding-container h-100 d-flex flex-column">
      <v-row class="single-progressbar-set-card-header">
        <p class="text-overline mb-0">{{ title }}</p>
        <p class="caption ml-auto mt-2 mb-0" v-if="reportedAt">
          <span class="text-capitalize">{{ $t("reported") }}</span>
          {{
            dateFns.formatDistance(new Date(date), new Date(), {
              addSuffix: true,
              locale: dateFnsLocales[$i18n.locale.split("_")[0]],
            })
          }}
        </p>
        <p class="caption ml-auto mt-2 mb-0" v-else>
          {{ dateFns.format(new Date(date), "MM/dd/Y") }}
        </p>
      </v-row>

      <slot name="body"></slot>

      <v-row align="end" class="footer">
        <v-col
          cols="12"
          class="redirect-btn pa-0 d-flex justify-end"
          v-if="!updateMood"
        >
          <v-btn
            fab
            outlined
            class="d-inline-block mr-3"
            @click="$emit('edit')"
            v-if="editBtn"
          >
            <span class="icomoon icon-edit"></span>
          </v-btn>
          <v-btn fab outlined class="d-inline-block" @click="redirect">
            <span class="icomoon icon-up"></span>
          </v-btn>
        </v-col>

        <v-col
          cols="12"
          class="update-actions pa-0 d-flex justify-end text-uppercase"
          v-else
        >
          <v-btn
            depressed
            outlined
            right
            class="d-inline-block mr-8"
            @click="$emit('cancel')"
          >
            {{ $t("cancel") }} {{ $t("changes") }}
          </v-btn>
          <v-btn
            depressed
            dark
            right
            color="#1A1A1A"
            class="d-inline-block"
            @click="$emit('save')"
          >
            {{ $t("save") }} {{ $t("changes") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import dataFnsMixin from "@/mixins/date-fns.js";

export default {
  name: "CardsContainer",

  props: {
    title: {
      type: String,
      default: "",
    },
    date: {
      type: String,
      default: "",
    },
    reportedAt: {
      type: Boolean,
      default: false,
    },
    redirectionLink: {
      type: [String, Object],
      default: "",
    },
    editBtn: {
      type: Boolean,
      default: false,
    },
    isHighlighted: {
      type: Boolean,
      default: false,
    },
    updateMood: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [dataFnsMixin],

  methods: {
    redirect() {this.$router.push(this.redirectionLink);
    },
  },
};
</script>

<style lang="scss" src="@/assets/sass/components/views/dashboard/members/id/partial/cards/cards-container.scss">
</style>