<template>
  <v-row class="body-map partial-body-map">
    <v-col cols="12" class="d-flex align-end justify-center pb-6">
      <div class="img-container right-hand-container d-inline-block">
        <v-img src="/imgs/right-hand.svg">
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-hand-thumb-interphalangeal-joint ${
              joints.find(joint => joint.option == ('right-hand-thumb-interphalangeal-joint'))
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                (joints.find(joint => joint.option == ('right-hand-thumb-interphalangeal-joint'))) &
                updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-hand-thumb-metacarpophalangeal-joint ${
              joints.find(joint => joint.option == ('right-hand-thumb-metacarpophalangeal-joint'))
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                (joints.find(joint => joint.option == ( 'right-hand-thumb-metacarpophalangeal-joint'))) &
                updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-hand-index-proximal-interphalangeal-joint ${
              joints.find(joint => joint.option == ('right-hand-index-proximal-interphalangeal-joint'))
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                (joints.find(joint => joint.option == (                  'right-hand-index-proximal-interphalangeal-joint'))) &
                updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-hand-index-metacarpophalangeal-joint ${
              joints.find(joint => joint.option == ('right-hand-index-metacarpophalangeal-joint'))
                ? 'selected-joint'
                : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon
              v-if="
                (joints.find(joint => joint.option == (                  'right-hand-index-metacarpophalangeal-joint'))) &
                updateMood
              "
            >
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-hand-middle-proximal-interphalangeal-joint ${
              joints.find(joint => joint.option == ('right-hand-middle-proximal-interphalangeal-joint')) ? 'selected-joint' : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon v-if="(joints.find(joint => joint.option == ('right-hand-middle-proximal-interphalangeal-joint'))) & updateMood">
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-hand-middle-metacarpophalangeal-joint ${
              joints.find(joint => joint.option == ('right-hand-middle-metacarpophalangeal-joint')) ? 'selected-joint' : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon v-if="(joints.find(joint => joint.option == ('right-hand-middle-metacarpophalangeal-joint'))) & updateMood">
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-hand-ring-proximal-interphalangeal-joint ${
              joints.find(joint => joint.option == ('right-hand-ring-proximal-interphalangeal-joint')) ? 'selected-joint' : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon v-if="(joints.find(joint => joint.option == ('right-hand-ring-proximal-interphalangeal-joint') ))& updateMood">
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-hand-ring-metacarpophalangeal-joint ${
              joints.find(joint => joint.option == ('right-hand-ring-metacarpophalangeal-joint')) ? 'selected-joint' : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon v-if="(joints.find(joint => joint.option == ('right-hand-ring-metacarpophalangeal-joint') ))& updateMood">
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-hand-pinky-proximal-interphalangeal-joint ${
              joints.find(joint => joint.option == ('right-hand-pinky-proximal-interphalangeal-joint')) ? 'selected-joint' : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon v-if="(joints.find(joint => joint.option == ('right-hand-pinky-proximal-interphalangeal-joint'))) & updateMood">
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-hand-pinky-metacarpophalangeal-joint ${
              joints.find(joint => joint.option == ('right-hand-pinky-metacarpophalangeal-joint')) ? 'selected-joint' : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon v-if="(joints.find(joint => joint.option == ('right-hand-pinky-metacarpophalangeal-joint'))) & updateMood">
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
        </v-img>
        <span>{{$t("right_hand")}}</span>
      </div>
      <div class="img-container body-container d-inline-block">
        <v-img src="/imgs/body.svg">
        
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-wrist-joint ${
              joints.find(joint => joint.option == ('right-wrist-joint')) ? 'selected-joint' : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon v-if=" joints.find(joint => joint.option == ('right-wrist-joint')) & updateMood">
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>

          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-elbow-joint ${
              joints.find(joint => joint.option == ('right-elbow-joint')) ? 'selected-joint' : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon v-if="(joints.find(joint => joint.option == ('right-elbow-joint'))) & updateMood">
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>

          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-shoulder-joint ${
              joints.find(joint => joint.option == ('right-shoulder-joint')) ? 'selected-joint' : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
            
          >
            <v-icon v-if="(joints.find(joint => joint.option == ('right-shoulder-joint'))) & updateMood">
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>

          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-shoulder-joint ${
              joints.find(joint => joint.option == ('left-shoulder-joint')) ? 'selected-joint' : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon v-if="(joints.find(joint => joint.option == ('left-shoulder-joint'))) & updateMood">
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>

          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-elbow-joint ${
              joints.find(joint => joint.option == ('left-elbow-joint')) ? 'selected-joint' : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon v-if="(joints.find(joint => joint.option == ('left-elbow-joint'))) & updateMood">
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>

          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-wrist-joint ${
              joints.find(joint => joint.option == ('left-wrist-joint')) ? 'selected-joint' : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon v-if="(joints.find(joint => joint.option == ('left-wrist-joint'))) & updateMood">
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>

          <v-btn
            :class="`body-map-circle  d-inline-block d-flex right-knee-joint ${
              joints.find(joint => joint.option == ('right-knee-joint')) ? 'selected-joint' : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon v-if="(joints.find(joint => joint.option == ('right-knee-joint'))) & updateMood">
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>

          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-knee-joint ${
              joints.find(joint => joint.option == ('left-knee-joint')) ? 'selected-joint' : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon v-if="(joints.find(joint => joint.option == ('left-knee-joint'))) & updateMood">
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
        </v-img>
        <span></span>
      </div>
      <div class="img-container left-hand-container d-inline-block">
        <v-img src="/imgs/left-hand.svg">
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-hand-thumb-interphalangeal-joint ${
              joints.find(joint => joint.option == ('left-hand-thumb-interphalangeal-joint')) ? 'selected-joint' : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon v-if="(joints.find(joint => joint.option == ('left-hand-thumb-interphalangeal-joint'))) & updateMood">
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-hand-thumb-metacarpophalangeal-joint ${
              joints.find(joint => joint.option == ('left-hand-thumb-metacarpophalangeal-joint')) ? 'selected-joint' : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon v-if="(joints.find(joint => joint.option == ('left-hand-thumb-metacarpophalangeal-joint'))) & updateMood">
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-hand-index-proximal-interphalangeal-joint ${
              joints.find(joint => joint.option == ('left-hand-index-proximal-interphalangeal-joint')) ? 'selected-joint' : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon v-if="(joints.find(joint => joint.option == ('left-hand-index-proximal-interphalangeal-joint'))) & updateMood">
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-hand-index-metacarpophalangeal-joint ${
              joints.find(joint => joint.option == ('left-hand-index-metacarpophalangeal-joint')) ? 'selected-joint' : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon v-if="(joints.find(joint => joint.option == ('left-hand-index-metacarpophalangeal-joint'))) & updateMood">
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-hand-middle-proximal-interphalangeal-joint ${
              joints.find(joint => joint.option == ('left-hand-middle-proximal-interphalangeal-joint')) ? 'selected-joint' : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon v-if="(joints.find(joint => joint.option == ('left-hand-middle-proximal-interphalangeal-joint'))) & updateMood">
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-hand-middle-metacarpophalangeal-joint ${
              joints.find(joint => joint.option == ('left-hand-middle-metacarpophalangeal-joint')) ? 'selected-joint' : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon v-if="(joints.find(joint => joint.option == ('left-hand-middle-metacarpophalangeal-joint'))) & updateMood">
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-hand-ring-proximal-interphalangeal-joint ${
              joints.find(joint => joint.option == ('left-hand-ring-proximal-interphalangeal-joint')) ? 'selected-joint' : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon v-if="(joints.find(joint => joint.option == ('left-hand-ring-proximal-interphalangeal-joint'))) & updateMood">
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-hand-ring-metacarpophalangeal-joint ${
              joints.find(joint => joint.option == ('left-hand-ring-metacarpophalangeal-joint')) ? 'selected-joint' : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon v-if="(joints.find(joint => joint.option == ('left-hand-ring-metacarpophalangeal-joint'))) & updateMood">
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-hand-pinky-metacarpophalangeal-joint ${
              joints.find(joint => joint.option == ('left-hand-pinky-metacarpophalangeal-joint')) ? 'selected-joint' : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon v-if="(joints.find(joint => joint.option == ('left-hand-pinky-metacarpophalangeal-joint'))) & updateMood">
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
          <v-btn
            :class="`body-map-circle  d-inline-block d-flex left-hand-pinky-proximal-interphalangeal-joint ${
              joints.find(joint => joint.option == ('left-hand-pinky-proximal-interphalangeal-joint')) ? 'selected-joint' : ''
            } ${updateMood ? 'update-mood' : ''}`"
            fab
            depressed
            :disabled="!updateMood"
          >
            <v-icon v-if="(joints.find(joint => joint.option == ('left-hand-pinky-proximal-interphalangeal-joint'))) & updateMood">
              icon-tick
            </v-icon>
            <v-icon v-else> icon-close </v-icon>
          </v-btn>
        </v-img>
        <span>{{$t("left_hand")}}</span>
      </div>
    </v-col>
    <v-col cols="12" class="d-flex justify-center main-title pt-0 pb-0">
      <p class="mb-5">{{ title }}</p>
    </v-col>
    <v-col cols="12" class="d-flex justify-center main-title pt-0 pb-0">
      <p class="mb-0 number">{{ joints.length }}</p>
    </v-col>
  </v-row>
</template>


<script>
export default {
  name: "PartialBodyMap",

  props: {
    title: {
      type: String,
      default: "",
    },
    joints: {
      type: Array,
      default: () => [],
    },
    updateMood: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped src="@/assets/sass/components/views/dashboard/members/id/partial-body-map.scss">
</style>
