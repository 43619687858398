import store from "../store";
import router from "../router";
import i18n from "@/plugins/i18n";

export default function twoFactorAuthMiddleware(to) {
  if (
    store.getters["auth/user"]?.enable_two_factor_auth &&
    !store.getters["auth/user"]?.is_two_factor_auth &&
    to.name !== "account-auth"
  ) {
    return router.push({
      params: {
        lang: i18n.locale,
      },
      name: "account-auth",
    });
  }
}
