<template>
  <detailed-view-container
    questionnaireTitle="Health Assessment Questionnaire"
    :series="series"
  >
    <template v-slot:questionnnairePanel>
      <HAQQuestionnairePanels
        :questionnaires="questionnaires"
        :questions="questionnaireQuestions"
      />
    </template>
  </detailed-view-container>
</template>

<script>
const components = {
  HAQQuestionnairePanels: () =>
    import(
      "@/components/views/dashboard/members/id/partial/questionnaires/components/HAQQuestionnairePanels.vue"
    ),
  DetailedViewContainer: () =>
    import(
      "@/components/views/dashboard/members/id/partial/questionnaires/DetailedViewContainer.vue"
    ),
};

export default {
  name: "HAQ",

  data() {
    return {
      series: [
        {
          name: "series-1",
          data: [
            null,
            null,
            null,
            null,
            2,
            7,
            3,
            1,
            10,
            5,
            6,
            null,
            null,
            null,
            null,
          ],
        },
      ],
      questionnaires: [
        {
          date: "May 28, 2020",
          score: 2,
        },
        {
          date: "September 18, 2020",
          score: 1,
        },
        {
          date: "May 04, 2020",
          score: 2,
        },
      ],
      questionnaireQuestions: [
        {
          type: "MCQTable",
          title: "Vestirse y asearse",
          data: [
            {
              name: "Vestirse solo, incluyendo abrocharse los botones y atarse los cordones de los zapatos?",
              noDifficulty: false,
              littleDifficulty: false,
              SomeDifficulty: false,
              lotOfDifficulty: true,
              unableToDo: false,
            },
            {
              name: "Enjabonarse la cabeza?",
              noDifficulty: false,
              littleDifficulty: true,
              SomeDifficulty: false,
              lotOfDifficulty: false,
              unableToDo: false,
            },
          ],
        },
        {
          type: "MCQTable",
          title: "Levantarse",
          data: [
            {
              name: "Levantarse de una silla sin brazos?",
              noDifficulty: false,
              littleDifficulty: false,
              SomeDifficulty: false,
              lotOfDifficulty: true,
              unableToDo: false,
            },
            {
              name: "Acostarse y levantarse de la cama?",
              noDifficulty: false,
              littleDifficulty: true,
              SomeDifficulty: false,
              lotOfDifficulty: false,
              unableToDo: false,
            },
          ],
        },
        {
          type: "MCQTable",
          title: "Comer",
          data: [
            {
              name: "Cortar un filete de carne?",
              noDifficulty: false,
              littleDifficulty: false,
              SomeDifficulty: false,
              lotOfDifficulty: true,
              unableToDo: false,
            },
            {
              name: "Abrir un cartón de leche nuevo?",
              noDifficulty: false,
              littleDifficulty: true,
              SomeDifficulty: false,
              lotOfDifficulty: false,
              unableToDo: false,
            },
            {
              name: "Servirse la bebida?",
              noDifficulty: false,
              littleDifficulty: true,
              SomeDifficulty: false,
              lotOfDifficulty: false,
              unableToDo: false,
            },
          ],
        },
        {
          type: "MCQTable",
          title: "Caminar",
          data: [
            {
              name: "Caminar fuera de casa por un terreno llano?",
              noDifficulty: false,
              littleDifficulty: false,
              SomeDifficulty: false,
              lotOfDifficulty: true,
              unableToDo: false,
            },
            {
              name: "Subir 5 escalones?",
              noDifficulty: false,
              littleDifficulty: true,
              SomeDifficulty: false,
              lotOfDifficulty: false,
              unableToDo: false,
            },
          ],
        },
        {
          type: "MCQTable",
          title: "Alcanzar",
          data: [
            {
              name: "Coger un paquete de azúcar de 1kg de una estantería colocada por encima de su cabeza?",
              noDifficulty: false,
              littleDifficulty: false,
              SomeDifficulty: false,
              lotOfDifficulty: true,
              unableToDo: false,
            },
            {
              name: "Agacharse y coger ropa del suelo?",
              noDifficulty: false,
              littleDifficulty: true,
              SomeDifficulty: false,
              lotOfDifficulty: false,
              unableToDo: false,
            },
          ],
        },
        {
          type: "MCQTable",
          title: "Prensión",
          data: [
            {
              name: "Abrir la puerta de un coche?",
              noDifficulty: false,
              littleDifficulty: false,
              SomeDifficulty: false,
              lotOfDifficulty: true,
              unableToDo: false,
            },
            {
              name: "Abrir tarros cerrados que ya antes habían sido abiertos?",
              noDifficulty: false,
              littleDifficulty: true,
              SomeDifficulty: false,
              lotOfDifficulty: false,
              unableToDo: false,
            },
            {
              name: "Abrir y cerrar los grifos?",
              noDifficulty: false,
              littleDifficulty: true,
              SomeDifficulty: false,
              lotOfDifficulty: false,
              unableToDo: false,
            },
          ],
        },
        {
          type: "MCQTable",
          title: "Otras",
          data: [
            {
              name: "Hacer los recados y las compras?",
              noDifficulty: false,
              littleDifficulty: false,
              SomeDifficulty: false,
              lotOfDifficulty: true,
              unableToDo: false,
            },
            {
              name: "Entrar y salir de un coche?",
              noDifficulty: false,
              littleDifficulty: true,
              SomeDifficulty: false,
              lotOfDifficulty: false,
              unableToDo: false,
            },
            {
              name: "Hacer tareas de casa como barrer o lavar los platos?",
              noDifficulty: false,
              littleDifficulty: true,
              SomeDifficulty: false,
              lotOfDifficulty: false,
              unableToDo: false,
            },
          ],
        },
        {
          type: "checkList",
          title: "Señale si utiliza alguno de estos utensilios habitualmente",
          data: [
            {
              text: "Vestirse, asearse",
              value: false,
            },

            {
              text: "Levantarse",
              value: true,
            },
            { text: "Comer", value: true },
            { text: "Caminar, pasear", value: false },
            {
              text: "Higiene personal",
              value: false,
            },
            {
              text: "Alcanzar",
              value: true,
            },
            {
              text: "Abrir y cerrar cosas (prensión)",
              value: false,
            },
            {
              text: "Recados y tareas de casa",
              value: true,
            },
          ],
        },
        {
          type: "checkList",
          title: "Señale si utiliza alguno de estos utensilios habitualmente",
          data: [
            {
              text: "Cubiertos de mango ancho",
              value: false,
            },
            {
              text: "Bastón, muletas, andador o silla de ruedas",
              value: true,
            },
            { text: "Asiento o barra especial para el baño", value: false },
            { text: "Asiento alto para el retrete", value: false },
            {
              text: "Abridor para tarros previamente abiertos",
              value: false,
            },
          ],
        },
      ],
    };
  },

  components,
};
</script>