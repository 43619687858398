import i18n from '@/plugins/i18n'

export default function routeLangMiddleware(to)
{
  let language = i18n.availableLocales.includes(to.params.lang) ? to.params.lang : null;

  if (!language)
  {
    language = 'en_us'
  }

  i18n.locale = language
}