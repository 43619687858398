<template>
  <v-container class="progressbar-container" justify="center">
    <v-row
      v-for="symptom in symptoms"
      class="pb-5 mb-13 justify-center"
      :key="symptom.question_id"
    >
      <v-col cols="3" class="text-center label d-flex">{{ symptom.question_options[0].option_text }}</v-col>

      <v-col cols="6">
        <v-row class="justify-center">
          <v-col cols="12" class="text-center value">
            {{ symptom.answer.option_value }}
          </v-col>
          <v-col cols="12">
            <v-progress-linear
              :value="symptom.answer.option_value * 10"
              class="m-auto"
              :style="`background:${getProgressBarColor(symptom.answer.option_value)}`"
              rounded
            >
            </v-progress-linear>
          </v-col>

          <progress-bar-dots :indices="10" />
        </v-row>
      </v-col>

      <v-col cols="3" class="text-center label d-flex">{{ symptom.question_options[10].option_text }}</v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProgressBarDots from "./ProgressBarDots.vue";
export default {
  name: "EdmontonSymptomsProgressBars",

  components: {
    ProgressBarDots,
  },

  props: {
    symptoms: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    getProgressBarColor(value) {
      if (value <= 30) {
        return "linear-gradient(90deg, #6CC0A6 30%, #F9C361 95%)";
      }
      if (value <= 60) {
        return "linear-gradient(90deg, #6CC0A6 0%, #F9C361 49.15%)";
      }

      return "linear-gradient(90deg, #6CC0A6 0%, #F9C361 49.15%, #ED7378 100%)";
    },
  },
};
</script>