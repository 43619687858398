<template>
    <v-container class="chats-navigator pt-4">
        <v-row>
            <!-- search bar  -->
            <!-- <v-col cols="12" class="search-bar pr-5">
        <v-text-field :label="`${$t('search')}`" dense @input="serachMessages">
          <template v-slot:prepend-inner>
            <span class="icomoon icon-search"></span>
          </template>
        </v-text-field>
      </v-col> -->

            <v-col cols="12" class="chats mt-7">
                <template v-if="!chats">
                    <v-skeleton-loader
                        v-for="i in 4"
                        :key="i"
                        type="list-item-avatar"
                        class="chat-box mb-1"
                    />
                </template>

                <template v-else>
                    <div
                        v-for="chat in chats"
                        :key="chat.id"
                        class="chat-box mb-1 justify-center align-center d-flex flex-direction-row"
                        :class="{
                            unread: chat.unread_messages_count > 0,
                            active: chatIsActive({ selectedChat, chat }),
                        }"
                        @click="$emit('chatSelected', chat.id)"
                    >
                        <v-avatar size="43" class="flex-grow-0">
                            <img
                                :src="
                                    chat.patient_avatar || getDefaultAvatar({ selectedChat, chat })
                                "
                            />
                        </v-avatar>

                        <div class="message-container pl-3 flex-grow-1">
                            <p class="sender pa-0 mb-1">
                                {{ getName(chat) }}
                            </p>
                            <p class="message pa-0">
                                {{ chat.last_message ? chat.last_message.message : "" }}
                            </p>
                        </div>
                        <div class="chat-info flex-grow-1">
                            <span class="sent-at">
                                {{
                                    chat.last_message
                                        ? getMessageDate(chat.last_message.created_at)
                                        : ""
                                }}
                            </span>
                            <div
                                class="unread-message-count bold rounded-circle"
                                :class="{
                                    'transparent transparent--text':
                                        chat.unread_messages_count == 0,
                                }"
                            >
                                {{
                                    chat.unread_messages_count < 100
                                        ? chat.unread_messages_count
                                        : '+99'
                                }}
                            </div>
                        </div>
                    </div>
                </template>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import dataFnsMixin from "@/mixins/date-fns.js";
import chatMixin from "@/mixins/chat.js";

export default {
    name: "ChatsNavigator",

    props: {
        chats: {
            type: Array,
            default: () => [],
        },
        selectedChat: {
            type: Object,
            default: () => {},
        },
    },

    mixins: [dataFnsMixin, chatMixin],

    methods: {
        serachMessages(e) {
            this.$store.dispatch("members/searchMembers", e);
        },
        getMessageDate(date) {
            date = new Date(date);
            let format = "MMM dd, yyyy";

            if (this.dateFns.isThisWeek(date)) {
                format = this.dateFns.isToday(date) ? "HH:mm" : "E";
            }

            return this.dateFns.format(new Date(date), format);
        },
        getName(chat) {
            if (!chat) {
                return null;
            }

            return chat.patient_first_name?.length > 1
                ? `${chat.patient_first_name} ${chat.patient_last_name ?? ""}`
                : chat.patient_email;
        },
    },
};
</script>

<style lang="scss" src="@/assets/sass/components/messages/chats-navigator.scss"></style>
