import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/plugins/i18n'
import AuthRoutes from '@/router/routes/auth.js'
import HomeRoutes from './routes/home'
import DashboardRoutes from './routes/dashboard'
import AdminRoutes from './routes/admin'

Vue.use(VueRouter)

const childrenRoutes = [
  ...AuthRoutes,
  ...HomeRoutes,
  ...DashboardRoutes,
  ...AdminRoutes,
  {
    path: '/*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '@/components/PageNotFound.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: `${ i18n.locale }`
    },
    {
      path: '/:lang',
      component: {
        render(c) { return c('router-view') }
      },
      children: childrenRoutes
    }
  ]
})

export default router
