import axios from 'axios';
import router from '@/router';
import i18n from '@/plugins/i18n';

export default {
    namespaced: true,

    state: {
        membersList: [],
        searchResultedMembers: {},
        loading: false,
        member: null
    },

    getters: {
        membersList(state) {
            /*eslint-disable no-prototype-builtins */
            return state.searchResultedMembers.hasOwnProperty('members') ? state.searchResultedMembers : state.membersList;
        },
        loading(state) {
            return state.loading;
        },
        member(state) {
            return state.member;
        }
    },

    mutations: {
        SET_MEMBERS_LIST(state, membersList) {
            state.membersList = membersList;
        },
        SET_MEMBER(state, member) {
            state.member = member;
        },
        SET_SEARCH_RESULTED_MEMBERS(state, searchResultedMembers) {
            state.searchResultedMembers = searchResultedMembers;
        },
        SET_LOADING(state, loading) {
            state.loading = loading;
        }
    },

    actions: {
        fetchMembers({ commit }) {
            commit('SET_LOADING', true);

            axios
                .get("/dashboard/users/client-wise")
                .then((res) => {
                    commit('SET_MEMBERS_LIST', res.data.data);
                    commit('SET_LOADING', false);
                })
                .catch((e) => {
                    commit('SET_LOADING', false);
                });
        },
        searchMembers({ state, commit }, keyword) {
            if (state?.membersList?.members) {
                let searchResultMembers = state.membersList.members.filter(member => {
                    return member.email.toLowerCase().includes(keyword.toLowerCase()) || member.condition.toLowerCase().includes(keyword.toLowerCase())
                });

                let result = { ...state.membersList, members: searchResultMembers };

                commit('SET_SEARCH_RESULTED_MEMBERS', result);
            }
        },
        getMember({ state, commit, rootGetters }) {
            axios.get(`dashboard/users/${router.currentRoute.params.id}`)
                .then((res) => {
                    commit('SET_MEMBER', res.data.data[0]);

                    let text = '';

                    if (state.member.name && state.member.surname) {
                        text = `${state.member.name} ${state.member.surname}`;
                    } else {
                        text = state.member.email;
                    }                

                    Object.assign(router.currentRoute.meta.breadcrumbs[1], {
                        text: text,
                        translatedText: text,
                        href: `/${i18n.locale}/members/${router.currentRoute.params.id}`,
                        disabled: rootGetters["auth/user"].theme_config.key !== "dt1"
                            ? false
                            : true,
                    });
                });
        }
    }
};
