<template>
  <v-row>
    <v-container class="padding-container detailed-view-container pb-0">
      <v-row>
        <span
          class="back-to-review w-100"
          @click="
            $router.push({
              name: 'members-profile',
              params: {
                id: $route.params.id,
              },
            })
          "
        >
          <v-icon>mdi-chevron-left</v-icon>Back to Overview
        </span>

        <h3 class="questionnnaire-title pl-2">
          {{ questionnaireTitle }}
        </h3>
      </v-row>

      <v-row class="evolution" v-if="series && series.length > 0">
        <v-col cols="2" class="sub-title ml-2 ma-auto mr-0">
          {{ $t("evolution") }}
        </v-col>
        <v-col cols="9" class="pt-1" v-if="multiGraphs">
          <v-select
            hide-details
            name="graphs"
            id="graphs"
            class="graphsSelector pa-0"
            append-icon="icomoon  icon-arrow-down"
            :items="graphOptions"
            v-model="selectedOption"
            :menu-props="{
              'offset-y': true,
              'min-width': 200,
              'nudge-left': 17,
              'nudge-top': -3.5,
            }"
            @change="$emit('graphChanged', selectedOption)"
          >
            >
          </v-select>
        </v-col>
        <v-col cols="12" class="pa-0">
          <apexchart
            type="bar"
            :options="options"
            :series="series"
            height="315"
          ></apexchart>
        </v-col>
      </v-row>

      <slot name="questionnnairePanel"></slot>
    </v-container>
  </v-row>
</template>

<script>
export default {
  name: "DetailedViewContainer",

  props: {
    questionnaireTitle: {
      type: String,
      default: "",
    },
    graphOptions: {
      type: Array,
      default: () => [],
    },
    multiGraphs: {
      type: Boolean,
      default: false,
    },
    questionnaire: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      selectedOption:
        this.graphOptions?.length > 0 ? this.graphOptions[0] : null,
      isDoubleGraph: this.series?.length > 1,
    };
  },

  computed: {
    options: function () {
      return {
        chart: {
          id: "evolution-charts",
          toolbar: {
            show: false,
          },
        },
        grid: {
          strokeDashArray: 7,
          yaxis: {
            lines: {
              show: true,
              style: {
                colors: ["#310F00"],
                lines: "dasshed",
              },
            },
          },
        },
        plotOptions: {
          bar: {
            columnWidth: (
              this.series[0]?.data?.length * (!this.isDoubleGraph ? 2.14 : 4.14)
            ).toString(),
            borderRadius: 12,
            colors: {
              backgroundBarColors: !this.isDoubleGraph
                ? [
                    "transparent",
                    "transparent",
                    "transparent",
                    "transparent",
                    ...Array(7).fill("#CAD2FF"),
                    "transparent",
                    "transparent",
                    "transparent",
                    "transparent",
                  ]
                : [],
              backgroundBarOpacity: 0.6,
              backgroundBarRadius: "12px",
            },
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          offsetY: -25,
          style: {
            fontSize: "16px",
            lineHeight: "19px",
            colors: ["#310F00"],
          },
        },
        xaxis: {
          categories: this.chartProgressBar.categories,
        },
        yaxis: {
          tickAmount: Math.max(...this.series.map((elm) => elm.data).flat()),
          max: Math.max(...this.series.map((elm) => elm.data).flat()),
          labels: {
            show: true,
          },
          lines: {
            show: true,
          },
        },
        colors: [
          this.$store.getters["auth/user"]?.theme_config
            ?.progress_bar_primary_color,
          this.$store.getters["auth/user"]?.theme_config
            ?.progress_bar_secondary_color,
        ],
        legend: {
          fontFamily: "Inter",
          fontSize: "16px",
          position: "top",
          horizontalAlign: "right",
          offsetY: -43,
          itemMargin: {
            horizontal: 10,
          },
          markers: {
            radius: 30,
            width: 16,
            height: 16,
            offsetX: -1,
            offsetY: 2.5,
          },
        },
      };
    },

    chartProgressBar: function () {
      let data = [];
      let categories = [];

      for (const index in this.questionnaire.answers) {
        categories.push(index);
        data.push(this.questionnaire.answers[index].score.score);
      }

      return {
        data: this.formatChartProgressBarData(data, null),
        categories: this.formatChartProgressBarData(categories, ""),
      };
    },
    series: function () {
      return [{ data: this.chartProgressBar.data }];
    },
  },

  watch: {
    series(newVal) {
      this.isDoubleGraph = newVal.length > 1;
    },
  },

  methods: {
    formatChartProgressBarData(data, emptyValues) {
      let formatedData = new Array(15).fill(emptyValues, 0);
      formatedData.splice(4, data.length, ...data);

      return formatedData;
    },
  },
};
</script>


<style lang="scss" src="@/assets/sass/components/views/dashboard/members/id/partial/questionnaires/detailed-view-container.scss">
</style>