import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import {BrowserTracing} from '@sentry/tracing';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: "https://ba89e3084b314f8c9a4fdcc63e2e25a3@o1043814.ingest.sentry.io/6013584",
    integrations: [new BrowserTracing()],
    environment: process.env.NODE_ENV,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}