<template>
  <v-row>
    <v-container class="padding-container detailed-view-container">
      <v-row>
        <span
          class="back-to-review w-100"
          @click="
            $router.push({
              name: 'members-profile',
              params: {
                id: $route.params.id,
              },
            })
          "
        >
          <v-icon>mdi-chevron-left</v-icon>Back to Overview
        </span>

        <h3 class="questionnnaire-title pl-2">
          Edmonton symptom assessment system (ESAS)
        </h3>
      </v-row>

      <v-row class="evolution">
        <v-col cols="6" class="sub-title pa-3 pt-0 mb-11">
          {{ $t("evolution") }}
        </v-col>
        <v-col cols="6" class="label pt-0 mb-11 d-flex justify-end">
          <div class="d-flex align-center">
            <div class="secondary rounded-circle d-inline-block first-digram-label"></div>
            <span class="text-capitalize">{{$t("current_report")}}</span>
          </div>
          <div class="d-flex align-center">
            <div class="secondary rounded-circle d-inline-block second-digram-label"></div>
            <span class="text-capitalize">{{$t("previous_report")}}</span>
          </div>
        </v-col>

        <v-col cols="12" class="pr-9 pl-9 graph">
          <!-- background dashed lines -->
          <hr
            v-for="(line, i) in 10"
            :key="`${i}-dashed-line`"
            :style="`${generateDashlinePosition(i)}`"
            :data-index="i + 1"
          />

          <v-row v-for="(item, i) in series" :key="i" class="graph-row">
            <v-col cols="3" class="pl-5">
              <div class="min-label">{{ item.minText }}</div>
            </v-col>

            <!-- progress bars  -->
            <v-col cols="6">
              <v-progress-linear
                buffer-value="50"
                :value="item.current * 10"
                :color="
                  $store.getters['auth/user'].theme_config
                    .progress_bar_primary_color
                "
                height="20"
              >
                <template v-slot:default="{ value }" class="test">
                  <div class="value pr-2" :style="`width:${value}%`">
                    {{ value / 10 }}
                  </div>
                </template>
              </v-progress-linear>

              <v-progress-linear
                :value="item.previous * 10"
                :color="
                  $store.getters['auth/user'].theme_config
                    .progress_bar_secondary_color
                "
                height="12"
                aria-label="false"
              >
              </v-progress-linear>
            </v-col>

            <v-col cols="3" class="pl-5">
              <div class="max-label">{{ item.maxText }}</div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <ESASQuestionnairePanels
        :questionnaires="questionnaires"
        :questions="questionnaireQuestions"
      />
    </v-container>
  </v-row>
</template>

<script>
const components = {
  ESASQuestionnairePanels: () =>
    import(
      "@/components/views/dashboard/members/id/partial/questionnaires/components/ESASQuestionnairePanels.vue"
    ),
  DetailedViewContainer: () =>
    import(
      "@/components/views/dashboard/members/id/partial/questionnaires/DetailedViewContainer.vue"
    ),
};

export default {
  name: "ESAS",

  data() {
    return {
      series: [
        {
          minText: "No pain",
          maxText: "Worst possible pain",
          current: 10,
          previous: 8,
        },
        {
          minText: "No tiredness",
          maxText: "Worst possible tiredness",
          current: 7,
          previous: 8,
        },
        {
          minText: "No drowsiness",
          maxText: "Worst possible drowsiness",
          current: 5,
          previous: 3,
        },
        {
          minText: "No nausea",
          maxText: "Worst possible nausea",
          current: 2,
          previous: 5,
        },
        {
          minText: "No Lack of Appetite",
          maxText: "Worst possible Lack of Appetite",
          current: 8,
          previous: 3,
        },
        {
          minText: "No Shortness of Breath",
          maxText: "Worst possible Shortness of Breath",
          current: 8,
          previous: 8,
        },
        {
          minText: "No Depression",
          maxText: "Worst possible Depression",
          current: 6,
          previous: 2,
        },
        {
          minText: "No tiredness",
          maxText: "Worst possible tiredness",
          current: 8,
          previous: 7,
        },
        {
          minText: "No drowsiness",
          maxText: "Worst possible drowsiness",
          current: 7,
          previous: 5,
        },
        {
          minText: "No pain",
          maxText: "Worst possible pain",
          current: 9,
          previous: 7,
        },
      ],
      questionnaires: [
        {
          date: "May 28, 2020",
          score: 20,
        },
        {
          date: "September 18, 2020",
          score: 10,
        },
        {
          date: "May 04, 2020",
          score: 12,
        },
        {
          date: "January 12, 2020",
          score: 8,
        },
        {
          date: "September 22, 2020",
          score: 10,
        },
        {
          date: "December 18, 2020",
          score: 6,
        },
        {
          date: "February 14, 2020",
          score: 2,
        },
      ],
      questionnaireQuestions: [
        {
          minText: "No pain",
          maxText: "Worst possible pain",
          value: 7,
        },
        {
          minText: "No tiredness",
          maxText: "Worst possible tiredness",
          value: 1,
        },
        {
          minText: "No drowsiness",
          maxText: "Worst possible drowsiness",
          value: 8,
        },
        {
          minText: "No pain",
          maxText: "Worst possible pain",
          value: 5,
        },
        {
          minText: "No tiredness",
          maxText: "Worst possible tiredness",
          value: 8,
        },
        {
          minText: "No drowsiness",
          maxText: "Worst possible drowsiness",
          value: 8,
        },
        {
          minText: "No pain",
          maxText: "Worst possible pain",
          value: 6,
        },
        {
          minText: "No tiredness",
          maxText: "Worst possible tiredness",
          value: 8,
        },
        {
          minText: "No drowsiness",
          maxText: "Worst possible drowsiness",
          value: 7,
        },
        {
          minText: "No pain",
          maxText: "Worst possible pain",
          value: 9,
        },
      ],
    };
  },

  components,

  methods: {
    generateDashlinePosition(i) {
      if (this.$vuetify.breakpoint.lg) {
        return `left: ${24.94 + i * 5.11}%;`;
      }
      if (this.$vuetify.breakpoint.md) {
        return `left: ${24.9 + i * 4.98}%;`;
      }
      return `left: ${24.9 + i * 4.8}%;`;
    },
  },
};
</script>

<style lang="scss" src="@/assets/sass/components/views/dashboard/members/id/partial/questionnaires/esas.scss">
</style>