<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="15"
    class="elevation-0"
    :loading="loading"
    :loading-text="$t('loading_text')"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    :itemClass="itemClass"
    hide-default-header
    @click:row="$emit('click:row', $event)"
    @update:sort-by="logActivity('sort')"
    @update:page="logActivity('paginate')"
  >
    <!-- dynamic slots -->
    <template
      v-for="slot in Object.keys($scopedSlots)"
      v-slot:[slot]="slotScope"
    >
      <slot :name="slot" v-bind="slotScope" />
    </template>

    <!-- custom headers -->
    <template v-slot:header="{ props }">
      <thead class="v-data-table-header">
        <tr>
          <th
            v-for="header in props.headers"
            :key="header.value"
            role="columnheader"
            scope="col"
            aria-sort="none"
            class="text-start sortable"
            :class="{ 'has-description': header.description }"
          >
            <!-- information icon   -->
            <v-tooltip
              top
              max-width="250px"
              color="#007C82"
              v-if="header.description"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="#666666" class="mr-1">
                  mdi-information-outline
                </v-icon>
              </template>
              <span>{{ header.description }}</span>
            </v-tooltip>

            <span>{{ header.text }}</span>

            <img
              src="@/assets/imgs/table-sorting.svg"
              class="sorting-icon"
              v-if="header.sortable"
              @click="sort(header.value)"
            />
          </th>
        </tr>
      </thead>
    </template>

    <!-- custom actions -->
    <template v-slot:item.actions="{ item }">
      <template v-if="item.readyToDelete">
        <v-btn
          hide-details
          class="
              ma-auto
              text-uppercase
              bold
              d-inline-flex
              align-center
              text-center
              delete
              ml-2
              mr-2
            "
          height="46"
          width="188"
        >
          {{ $t("delete") }}
        </v-btn>
        <v-btn
          hide-details
          class="
              ma-auto
              text-uppercase
              bold
              d-inline-flex
              align-center
              text-center
              ml-2
              cancel
            "
          height="46"
          width="188"
          @click="commentDialog = true"
        >
          {{ $t("cancel") }}
        </v-btn>
      </template>
      <template v-else>
        <div class="w-100 text-right">
          <span class="icon">
            <img src="@/assets/imgs/pen.svg" class="pen-icon" />
          </span>
          <span class="icon ml-4 mr-3" @click="deleteItem(item, $event)">
            <img
              src="@/assets/imgs/recycle-bin.svg"
              class="recycle-bin-icon "
            />
          </span>
        </div>
      </template>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data() {
    return {
      sortBy: null,
      sortDesc: false,
    };
  },

  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    itemClass: {
      type: [String, Function],
      default: "",
    },
  },

  methods: {
    sort(value) {
      if (this.sortBy == value) {
        return (this.sortDesc = !this.sortDesc);
      }
      this.sortBy = value;
    },
    logActivity(activityTag) {
      this.$store.dispatch("logActivity", {
        tag: activityTag,
        type: 1,
      });
    },
    deleteItem(item, e) {
      e.target.closest("tr").classList.add("ready-to-delete");
      this.deletedItem = { index: this.items.indexOf(item), item };
      this.$emit("deleteItem", this.deletedItem);
    },
  },
};
</script>

<style lang="scss" src="@/assets/sass/components/table.scss"></style>
