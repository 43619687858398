<template>
  <v-col cols="12">
    <cards-container
      class="progress-bars-card double-progressbar-set-card"
      :title="data.title"
      :date="data.last_report_date"
      :redirectionLink="redirectionLink"
      :isHighlighted="data.is_highlighted"
    >
      <template v-slot:body>
        <v-row class="body mt-7">
          <v-col :cols="7" class="pl-15 d-flex align-center">
            <div class="text-h5 d-inline-block last-report">
              <span class="text-capitalize">{{ $t("since") }}</span>
              <span class="text-lowercase"> {{ $t("last") }}</span>
              {{ $t("report") }}
            </div>
            <div class="text-h4 ml-10 d-inline-block">
              <v-list-item-title class="text-uppercase mb-2">
                #{{  data.trend.value > 0 ? $t("worst") : $t("reduce") }}
              </v-list-item-title>
              
              <v-list-item-subtitle>
                <span
                  :class="`incline-decline ${
                    data.trend.value > 0 ? 'up' : 'down'
                  }`"
                >
                  <span
                    :class="`icomoon  ${
                      data.trend.value > 0 ? 'icon-up' : 'icon-down'
                    }`"
                  ></span>
                  {{ Math.abs(data.trend.value) }}
                </span>
              </v-list-item-subtitle>
            </div>
          </v-col>
          <v-divider vertical inset></v-divider>
          <v-col :cols="5" class="custom_symptom_reported">
            <v-list-item-title class="text-uppercase mb-2">
              {{ $t("custom") }} {{ $t("symptom") }} {{ $t("reported") }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <span class="incline-decline text-capitalize">
                <span class="text-h5">{{
                  data.optional_answer.answer_text
                }}</span>
                {{ data.optional_answer.option.value }}
              </span>
            </v-list-item-subtitle>
          </v-col>
        </v-row>

        <ESASBodyMap
          :title="$t('swollen_joints')"
          :joints="data.last_report"
          :updateMood="updateMood"
        />
      </template>
    </cards-container>
  </v-col>
</template>

<script>
import CardsContainer from "./CardsContainer.vue";
import ESASBodyMap from "../ESASBodyMap.vue";

export default {
  name: "SummaryCardBodyMapEsasR",

  props: {
    redirectionLink: {
      type: String,
      default: "",
    },
    data: {
      type: Object,
      default: () => {},
    },
  },

  components: {
    CardsContainer,
    ESASBodyMap,
  },
};
</script>

<style lang="scss" src="@/assets/sass/components/views/dashboard/members/id/partial/cards/esas-card.scss">
</style>