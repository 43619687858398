<template>
  <v-row class="patient-reported-outcome mt-1">
    <v-col class="pt-0 pb-0" cols="12">
      <div class="h2 mb-5 pl-1 title">{{ $t("patient_reported_outcome") }}</div>
    </v-col>
    <v-col cols="12" class="p-0">
      <v-container class="padding-container pt-0">
        <v-row>
          <v-col cols="6">
            <SingleProgressBarSetCard
              title="Bienestar global"
              date="03/07/2021"
              :data="bienestarData"
            />
          </v-col>
          <v-col cols="6">
            <SingleProgressBarSetCard
              title="Pain VAS"
              date="04/08/2021"
              :data="painVASData"
            />
          </v-col>
          <v-col cols="12">
            <DasCard title="DAS-28" date="03/06/2021" :data="DasData" :joints="DasData" editBtn />
          </v-col>
          <v-col cols="6">
            <SingleProgressBarSetCard
              title="Health Assessment Questionnaire"
              date="03/05/2021"
              :data="HAQData"
            />
          </v-col>
          <v-col cols="6">
            <TextCard
              title="General Questions"
              date="07/09/2021"
              :data="GQData"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import SingleProgressBarSetCard from "@/components/views/dashboard/members/id/partial/cards/SingleProgressbarSetCard.vue";
import TextCard from "@/components/views/dashboard/members/id/partial/cards/TextCard";
import DasCard from "@/components/views/dashboard/members/id/partial/cards/DasCard";

export default {
  name: "PatientReportedOutcome",

  data() {
    return {
      bienestarData: { score: 8, totalScore: 10 },
      painVASData: { score: 8, totalScore: 10 },
      HAQData: { score: 2, totalScore: 3 },
      GQData: { score: 6, totalScore: 6 },
      DasData: {
        right_hand_1: true,
        right_hand_2: false,
        right_hand_3: false,
        right_hand_4: true,
        right_hand_5: false,
        right_hand_6: false,
        right_hand_7: false,
        right_hand_8: true,
        right_hand_9: false,
        right_hand_10: false,
        left_hand_1: false,
        left_hand_2: false,
        left_hand_3: false,
        left_hand_4: true,
        left_hand_5: false,
        left_hand_6: false,
        left_hand_7: false,
        left_hand_8: false,
        left_hand_9: true,
        left_hand_10: false,
        upper_body_1: false,
        upper_body_2: false,
        upper_body_3: true,
        upper_body_4: false,
        upper_body_5: true,
        upper_body_6: false,
        lower_body_1: true,
        lower_body_2: false,
      },
    };
  },

  props: {
    questionnaire: {
      type: Object,
      default: () => {},
    },
    reportedDate: {
      type: String,
      default: "",
    },
  },

  components: {
    SingleProgressBarSetCard,
    TextCard,
    DasCard,
  },
};
</script>