<template>
  <div class="my-account">
    <AdminTab :currentTab="0" />

    <admin-title />

    <v-container >
      <v-row class="basic-info mb-13">
        <template>
          <v-col class="card pt-1 mr-5" md="2">
            <p class="card-title text-uppercase mb-2">{{ $t("name") }}</p>
            <p class="card-body" v-if="$store.getters['auth/user']">
              {{ $store.getters["auth/user"].first_name }}
            </p>
          </v-col>
          <v-col class="card pt-1 mr-5" md="2">
            <p class="card-title text-uppercase mb-2">{{ $t("surname") }}</p>
            <p class="card-body" v-if="$store.getters['auth/user']">
              {{ $store.getters["auth/user"].last_name }}
            </p>
          </v-col>
          <v-col class="card pt-1 mr-5" lg="4" md="5">
            <p class="card-title text-uppercase mb-2">{{ $t("email") }}</p>
            <p class="card-body" v-if="$store.getters['auth/user']">
              {{ $store.getters["auth/user"].email }}
            </p>
          </v-col>
          <v-col class="card pt-1" md="2">
            <p class="card-title text-uppercase mb-2">{{ $t("role") }}</p>
            <p class="card-body">{{ $t("admin") }}</p>
          </v-col>
        </template>
      </v-row>

      <v-row>
        <v-col cols="12" class="update-password">
          <h2 class="pb-6">{{ $t("update_password") }}</h2>
        </v-col>
      </v-row>
      <v-form v-model="valid" ref="form">
        <v-row>
          <v-col md="6" cols="12" class="field-col">
            <v-text-field
              v-model="form.current_password"
              :rules="passRules"
              type="password"
              class="custom-input-field login_pass"
              :label="$t('current_password')"
              @keydown.enter="handleUpdatePassword"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6" cols="12" class="field-col">
            <v-text-field
              v-model="form.new_password"
              type="password"
              :rules="passRules"
              class="custom-input-field login_pass"
              :label="$t('new_password')"
              @keydown.enter="handleUpdatePassword"
            ></v-text-field>
          </v-col>
          <v-col md="6" cols="12" class="field-col">
            <v-text-field
              v-model="form.confirm_password"
              type="password"
              :rules="passConfirmationRules"
              class="custom-input-field login_pass"
              :label="$t('confirm_new_password')"
              @keydown.enter="handleUpdatePassword"
            ></v-text-field>
            <v-btn
              hide-details
              class="
                ma-auto
                text-uppercase
                bold
                d-flex
                align-center
                text-center
                float-right
                bg-theme-primary
                update
              "
              height="46"
              width="188"
              @click="handleUpdatePassword"
            >
              {{ $t("update") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import AdminTab from "@/components/views/admin/AdminTab.vue";
import AdminTitle from "../../components/views/admin/AdminTitle.vue";

export default {
  name: "MyAccount",

  data() {
    return {
      valid:false,
      form: {
				new_password: "",
				confirm_password: "",
				current_password: "",
			},
      passRules: [(v) => !!v || this.$t("password_required")],
      passConfirmationRules: [
        (v) => !!v || this.$t("password_required"),
        (form) =>
          this.form.new_password === this.form.confirm_password ||
          this.$t("pass_must_match"),
      ],
    };
  },

  components: {
    AdminTab,
    AdminTitle,
  },

	methods: {
		handleUpdatePassword() {
      if (!this.valid) {
        return;
      }

      this.$axios
				.post("users/change-password", this.form)
				.then((res) => {
          this.$toasted.success(this.$t("password_successfully_changed"));
          this.$refs.form.reset();
        })
				.catch((err) => {
          let errMsg = err?.response?.data?.error?.all_errors
						? Object.values(err.response.data.error.all_errors)[0][0]
						: err?.response?.data?.error?.message;

					if (errMsg) {
						console.log(errMsg);
					}					
				});
		},
	}
};
</script>

<style lang="scss" src="@/assets/sass/views/auth/my-account.scss"></style>
