<template>
  <v-row>
    <v-col cols="12" class="pb-0">
      <div
        class="esas-body-map-container d-flex align-start justify-center w-100"
      >
        <div class="img-container body-front-container">
          <v-container class="padding-container">
            <v-row>
              <v-col class="d-flex align-center pr-0 pl-0 text-right">
                <span class="left-text mt-15 pt-12">{{ $t("right_side") }}</span>
              </v-col>
              <v-col class="pr-0 pl-0">
                <v-img
                  src="/imgs/body-front.svg"
                  max-height="470"
                  max-width="146"
                  class="ma-auto"
                >
                  <v-btn
                    :class="`body-map-circle  d-inline-block d-flex front-right-hand ${
                      joints.find((joint) => joint.option == 'front-right-hand')
                        ? 'selected-joint'
                        : ''
                    } ${updateMood ? 'update-mood' : ''}`"
                    fab
                    depressed
                    :disabled="!updateMood"
                  >
                    <v-icon
                      v-if="
                        joints.find((joint) => joint.option == 'front-right-hand') &
                        updateMood
                      "
                    >
                      icon-tick
                    </v-icon>
                    <v-icon v-else> icon-close </v-icon>
                  </v-btn>
                  <v-btn
                    :class="`body-map-circle  d-inline-block d-flex front-right-lower-arm ${
                      joints.find((joint) => joint.option == 'front-right-lower-arm')
                        ? 'selected-joint'
                        : ''
                    } ${updateMood ? 'update-mood' : ''}`"
                    fab
                    depressed
                    :disabled="!updateMood"
                  >
                    <v-icon
                      v-if="
                        joints.find((joint) => joint.option == 'front-right-lower-arm') &
                        updateMood
                      "
                    >
                      icon-tick
                    </v-icon>
                    <v-icon v-else> icon-close </v-icon>
                  </v-btn>
                  <v-btn
                    :class="`body-map-circle  d-inline-block d-flex front-right-upper-arm ${
                      joints.find((joint) => joint.option == 'front-right-upper-arm')
                        ? 'selected-joint'
                        : ''
                    } ${updateMood ? 'update-mood' : ''}`"
                    fab
                    depressed
                    :disabled="!updateMood"
                  >
                    <v-icon
                      v-if="
                        joints.find((joint) => joint.option == 'front-right-upper-arm') &
                        updateMood
                      "
                    >
                      icon-tick
                    </v-icon>
                    <v-icon v-else> icon-close </v-icon>
                  </v-btn>
                  <v-btn
                    :class="`body-map-circle  d-inline-block d-flex front-neck ${
                      joints.find((joint) => joint.option == 'front-neck')
                        ? 'selected-joint'
                        : ''
                    } ${updateMood ? 'update-mood' : ''}`"
                    fab
                    depressed
                    :disabled="!updateMood"
                  >
                    <v-icon
                      v-if="
                        joints.find((joint) => joint.option == 'front-neck') &
                        updateMood
                      "
                    >
                      icon-tick
                    </v-icon>
                    <v-icon v-else> icon-close </v-icon>
                  </v-btn>
                  <v-btn
                    :class="`body-map-circle  d-inline-block d-flex front-trunk ${
                      joints.find((joint) => joint.option == 'front-trunk')
                        ? 'selected-joint'
                        : ''
                    } ${updateMood ? 'update-mood' : ''}`"
                    fab
                    depressed
                    :disabled="!updateMood"
                  >
                    <v-icon
                      v-if="
                        joints.find((joint) => joint.option == 'front-trunk') &
                        updateMood
                      "
                    >
                      icon-tick
                    </v-icon>
                    <v-icon v-else> icon-close </v-icon>
                  </v-btn>
                  <v-btn
                    :class="`body-map-circle  d-inline-block d-flex front-left-upper-arm ${
                      joints.find((joint) => joint.option == 'front-left-upper-arm')
                        ? 'selected-joint'
                        : ''
                    } ${updateMood ? 'update-mood' : ''}`"
                    fab
                    depressed
                    :disabled="!updateMood"
                  >
                    <v-icon
                      v-if="
                        joints.find((joint) => joint.option == 'front-left-upper-arm') &
                        updateMood
                      "
                    >
                      icon-tick
                    </v-icon>
                    <v-icon v-else> icon-close </v-icon>
                  </v-btn>
                  <v-btn
                    :class="`body-map-circle  d-inline-block d-flex front-left-lower-arm ${
                      joints.find((joint) => joint.option == 'front-left-lower-arm')
                        ? 'selected-joint'
                        : ''
                    } ${updateMood ? 'update-mood' : ''}`"
                    fab
                    depressed
                    :disabled="!updateMood"
                  >
                    <v-icon
                      v-if="
                        joints.find((joint) => joint.option == 'front-left-lower-arm') &
                        updateMood
                      "
                    >
                      icon-tick
                    </v-icon>
                    <v-icon v-else> icon-close </v-icon>
                  </v-btn>
                     <v-btn
                    :class="`body-map-circle  d-inline-block d-flex front-left-hand ${
                      joints.find((joint) => joint.option == 'front-left-hand')
                        ? 'selected-joint'
                        : ''
                    } ${updateMood ? 'update-mood' : ''}`"
                    fab
                    depressed
                    :disabled="!updateMood"
                  >
                    <v-icon
                      v-if="
                        joints.find((joint) => joint.option == 'front-left-hand') &
                        updateMood
                      "
                    >
                      icon-tick
                    </v-icon>
                    <v-icon v-else> icon-close </v-icon>
                  </v-btn>
                  <v-btn
                    :class="`body-map-circle  d-inline-block d-flex front-right-foot ${
                      joints.find((joint) => joint.option == 'front-right-foot')
                        ? 'selected-joint'
                        : ''
                    } ${updateMood ? 'update-mood' : ''}`"
                    fab
                    depressed
                    :disabled="!updateMood"
                  >
                    <v-icon
                      v-if="
                        joints.find((joint) => joint.option == 'front-right-foot') &
                        updateMood
                      "
                    >
                      icon-tick
                    </v-icon>
                    <v-icon v-else> icon-close </v-icon>
                  </v-btn>
                  <v-btn
                    :class="`body-map-circle  d-inline-block d-flex front-right-lower-leg ${
                      joints.find((joint) => joint.option == 'front-right-lower-leg')
                        ? 'selected-joint'
                        : ''
                    } ${updateMood ? 'update-mood' : ''}`"
                    fab
                    depressed
                    :disabled="!updateMood"
                  >
                    <v-icon
                      v-if="
                        joints.find(
                          (joint) => joint.option == 'front-right-lower-leg'
                        ) & updateMood
                      "
                    >
                      icon-tick
                    </v-icon>
                    <v-icon v-else> icon-close </v-icon>
                  </v-btn>
                  <v-btn
                    :class="`body-map-circle  d-inline-block d-flex front-right-upper-leg ${
                      joints.find((joint) => joint.option == 'front-right-upper-leg')
                        ? 'selected-joint'
                        : ''
                    } ${updateMood ? 'update-mood' : ''}`"
                    fab
                    depressed
                    :disabled="!updateMood"
                  >
                    <v-icon
                      v-if="
                        joints.find((joint) => joint.option == 'front-right-upper-leg') &
                        updateMood
                      "
                    >
                      icon-tick
                    </v-icon>
                    <v-icon v-else> icon-close </v-icon>
                  </v-btn>
                  <v-btn
                    :class="`body-map-circle  d-inline-block d-flex front-left-foot ${
                      joints.find((joint) => joint.option == 'front-left-foot')
                        ? 'selected-joint'
                        : ''
                    } ${updateMood ? 'update-mood' : ''}`"
                    fab
                    depressed
                    :disabled="!updateMood"
                  >
                    <v-icon
                      v-if="
                        joints.find((joint) => joint.option == 'front-left-foot') &
                        updateMood
                      "
                    >
                      icon-tick
                    </v-icon>
                    <v-icon v-else> icon-close </v-icon>
                  </v-btn>
                  <v-btn
                    :class="`body-map-circle  d-inline-block d-flex front-left-lower-leg ${
                      joints.find((joint) => joint.option == 'front-left-lower-leg')
                        ? 'selected-joint'
                        : ''
                    } ${updateMood ? 'update-mood' : ''}`"
                    fab
                    depressed
                    :disabled="!updateMood"
                  >
                    <v-icon
                      v-if="
                        joints.find((joint) => joint.option == 'front-left-lower-leg') &
                        updateMood
                      "
                    >
                      icon-tick
                    </v-icon>
                    <v-icon v-else> icon-close </v-icon>
                  </v-btn>
                  <v-btn
                    :class="`body-map-circle  d-inline-block d-flex front-left-upper-leg ${
                      joints.find((joint) => joint.option == 'front-left-upper-leg')
                        ? 'selected-joint'
                        : ''
                    } ${updateMood ? 'update-mood' : ''}`"
                    fab
                    depressed
                    :disabled="!updateMood"
                  >
                    <v-icon
                      v-if="
                        joints.find((joint) => joint.option == 'front-left-upper-leg') &
                        updateMood
                      "
                    >
                      icon-tick
                    </v-icon>
                    <v-icon v-else> icon-close </v-icon>
                  </v-btn>
                </v-img>
              </v-col>
              <v-col class="d-flex align-center pr-0 pl-0 text-left">
                <span class="right-text mt-15 pt-12">{{ $t("left_side") }}</span>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <div class="img-container body-back-container d-inline-block">
          <v-container class="padding-container">
            <v-row>
              <v-col class="d-flex align-center pr-0 pl-0 text-right">
                <span class="left-text mt-15 pt-15">{{ $t("right_side") }}</span>
              </v-col>
              <v-col class="pr-0 pl-0">
                <v-img
                  max-height="470"
                  max-width="146"
                  src="/imgs/body-back.svg"
                >
                  <v-btn
                    :class="`body-map-circle  d-inline-block d-flex back-right-hand ${
                      joints.find((joint) => joint.option == 'back-right-hand')
                        ? 'selected-joint'
                        : ''
                    } ${updateMood ? 'update-mood' : ''}`"
                    fab
                    depressed
                    :disabled="!updateMood"
                  >
                    <v-icon
                      v-if="
                        joints.find((joint) => joint.option == 'back-right-hand') &
                        updateMood
                      "
                    >
                      icon-tick
                    </v-icon>
                    <v-icon v-else> icon-close </v-icon>
                  </v-btn>
                  <v-btn
                    :class="`body-map-circle  d-inline-block d-flex back-right-lower-arm ${
                      joints.find((joint) => joint.option == 'back-right-lower-arm')
                        ? 'selected-joint'
                        : ''
                    } ${updateMood ? 'update-mood' : ''}`"
                    fab
                    depressed
                    :disabled="!updateMood"
                  >
                    <v-icon
                      v-if="
                        joints.find((joint) => joint.option == 'back-right-lower-arm') &
                        updateMood
                      "
                    >
                      icon-tick
                    </v-icon>
                    <v-icon v-else> icon-close </v-icon>
                  </v-btn>
                  <v-btn
                    :class="`body-map-circle  d-inline-block d-flex back-right-upper-arm ${
                      joints.find((joint) => joint.option == 'back-right-upper-arm')
                        ? 'selected-joint'
                        : ''
                    } ${updateMood ? 'update-mood' : ''}`"
                    fab
                    depressed
                    :disabled="!updateMood"
                  >
                    <v-icon
                      v-if="
                        joints.find((joint) => joint.option == 'back-right-upper-arm') &
                        updateMood
                      "
                    >
                      icon-tick
                    </v-icon>
                    <v-icon v-else> icon-close </v-icon>
                  </v-btn>
                  <v-btn
                    :class="`body-map-circle  d-inline-block d-flex back-neck ${
                      joints.find((joint) => joint.option == 'back-neck')
                        ? 'selected-joint'
                        : ''
                    } ${updateMood ? 'update-mood' : ''}`"
                    fab
                    depressed
                    :disabled="!updateMood"
                  >
                    <v-icon
                      v-if="
                        joints.find((joint) => joint.option == 'back-neck') &
                        updateMood
                      "
                    >
                      icon-tick
                    </v-icon>
                    <v-icon v-else> icon-close </v-icon>
                  </v-btn>
                  <v-btn
                    :class="`body-map-circle  d-inline-block d-flex back-trunk ${
                      joints.find((joint) => joint.option == 'back-trunk')
                        ? 'selected-joint'
                        : ''
                    } ${updateMood ? 'update-mood' : ''}`"
                    fab
                    depressed
                    :disabled="!updateMood"
                  >
                    <v-icon
                      v-if="
                        joints.find((joint) => joint.option == 'back-trunk') &
                        updateMood
                      "
                    >
                      icon-tick
                    </v-icon>
                    <v-icon v-else> icon-close </v-icon>
                  </v-btn>
                  <v-btn
                    :class="`body-map-circle  d-inline-block d-flex back-left-upper-arm ${
                      joints.find((joint) => joint.option == 'back-left-upper-arm')
                        ? 'selected-joint'
                        : ''
                    } ${updateMood ? 'update-mood' : ''}`"
                    fab
                    depressed
                    :disabled="!updateMood"
                  >
                    <v-icon
                      v-if="
                        joints.find((joint) => joint.option == 'back-left-upper-arm') &
                        updateMood
                      "
                    >
                      icon-tick
                    </v-icon>
                    <v-icon v-else> icon-close </v-icon>
                  </v-btn>
                  <v-btn
                    :class="`body-map-circle  d-inline-block d-flex back-left-lower-arm ${
                      joints.find((joint) => joint.option == 'back-left-lower-arm')
                        ? 'selected-joint'
                        : ''
                    } ${updateMood ? 'update-mood' : ''}`"
                    fab
                    depressed
                    :disabled="!updateMood"
                  >
                    <v-icon
                      v-if="
                        joints.find((joint) => joint.option == 'back-left-lower-arm') &
                        updateMood
                      "
                    >
                      icon-tick
                    </v-icon>
                    <v-icon v-else> icon-close </v-icon>
                  </v-btn>
                  <v-btn
                    :class="`body-map-circle  d-inline-block d-flex back-left-hand ${
                      joints.find((joint) => joint.option == 'back-left-hand')
                        ? 'selected-joint'
                        : ''
                    } ${updateMood ? 'update-mood' : ''}`"
                    fab
                    depressed
                    :disabled="!updateMood"
                  >
                    <v-icon
                      v-if="
                        joints.find((joint) => joint.option == 'back-left-hand') &
                        updateMood
                      "
                    >
                      icon-tick
                    </v-icon>
                    <v-icon v-else> icon-close </v-icon>
                  </v-btn>
                  <v-btn
                    :class="`body-map-circle  d-inline-block d-flex back-butt ${
                      joints.find((joint) => joint.option == 'back-butt')
                        ? 'selected-joint'
                        : ''
                    } ${updateMood ? 'update-mood' : ''}`"
                    fab
                    depressed
                    :disabled="!updateMood"
                  >
                    <v-icon
                      v-if="
                        joints.find((joint) => joint.option == 'back-butt') &
                        updateMood
                      "
                    >
                      icon-tick
                    </v-icon>
                    <v-icon v-else> icon-close </v-icon>
                  </v-btn>
                  <v-btn
                    :class="`body-map-circle  d-inline-block d-flex back-right-foot ${
                      joints.find((joint) => joint.option == 'back-right-foot')
                        ? 'selected-joint'
                        : ''
                    } ${updateMood ? 'update-mood' : ''}`"
                    fab
                    depressed
                    :disabled="!updateMood"
                  >
                    <v-icon
                      v-if="
                        joints.find((joint) => joint.option == 'back-right-foot') &
                        updateMood
                      "
                    >
                      icon-tick
                    </v-icon>
                    <v-icon v-else> icon-close </v-icon>
                  </v-btn>
                  <v-btn
                    :class="`body-map-circle  d-inline-block d-flex back-right-lower-leg ${
                      joints.find((joint) => joint.option == 'back-right-lower-leg')
                        ? 'selected-joint'
                        : ''
                    } ${updateMood ? 'update-mood' : ''}`"
                    fab
                    depressed
                    :disabled="!updateMood"
                  >
                    <v-icon
                      v-if="
                        joints.find(
                          (joint) => joint.option == 'back-right-lower-leg'
                        ) & updateMood
                      "
                    >
                      icon-tick
                    </v-icon>
                    <v-icon v-else> icon-close </v-icon>
                  </v-btn>
                  <v-btn
                    :class="`body-map-circle  d-inline-block d-flex back-right-upper-leg ${
                      joints.find((joint) => joint.option == 'back-right-upper-leg')
                        ? 'selected-joint'
                        : ''
                    } ${updateMood ? 'update-mood' : ''}`"
                    fab
                    depressed
                    :disabled="!updateMood"
                  >
                    <v-icon
                      v-if="
                        joints.find((joint) => joint.option == 'back-right-upper-leg') &
                        updateMood
                      "
                    >
                      icon-tick
                    </v-icon>
                    <v-icon v-else> icon-close </v-icon>
                  </v-btn>
                  <v-btn
                    :class="`body-map-circle  d-inline-block d-flex back-left-upper-leg ${
                      joints.find((joint) => joint.option == 'back-left-upper-leg')
                        ? 'selected-joint'
                        : ''
                    } ${updateMood ? 'update-mood' : ''}`"
                    fab
                    depressed
                    :disabled="!updateMood"
                  >
                    <v-icon
                      v-if="
                        joints.find((joint) => joint.option == 'back-left-upper-leg') &
                        updateMood
                      "
                    >
                      icon-tick
                    </v-icon>
                    <v-icon v-else> icon-close </v-icon>
                  </v-btn>
                  <v-btn
                    :class="`body-map-circle  d-inline-block d-flex back-left-lower-leg ${
                      joints.find((joint) => joint.option == 'back-left-lower-leg')
                        ? 'selected-joint'
                        : ''
                    } ${updateMood ? 'update-mood' : ''}`"
                    fab
                    depressed
                    :disabled="!updateMood"
                  >
                    <v-icon
                      v-if="
                        joints.find((joint) => joint.option == 'back-left-lower-leg') &
                        updateMood
                      "
                    >
                      icon-tick
                    </v-icon>
                    <v-icon v-else> icon-close </v-icon>
                  </v-btn>
                  <v-btn
                    :class="`body-map-circle  d-inline-block d-flex back-left-foot ${
                      joints.find((joint) => joint.option == 'back-left-foot')
                        ? 'selected-joint'
                        : ''
                    } ${updateMood ? 'update-mood' : ''}`"
                    fab
                    depressed
                    :disabled="!updateMood"
                  >
                    <v-icon
                      v-if="
                        joints.find((joint) => joint.option == 'back-left-foot') &
                        updateMood
                      "
                    >
                      icon-tick
                    </v-icon>
                    <v-icon v-else> icon-close </v-icon>
                  </v-btn>
                </v-img>
              </v-col>
              <v-col class="d-flex align-center pr-0 pl-0 text-left">
                <span class="right-text mt-15 pt-15">{{ $t("left_side") }}</span>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </v-col>
  </v-row>
</template>


<script>
export default {
  name: "ESASBodyMap",

  props: {
    title: {
      type: String,
      default: "",
    },
    joints: {
      type: Array,
      default: () => [],
    },
    updateMood: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped src="@/assets/sass/components/views/dashboard/members/id/esas-body-map.scss">
</style>
