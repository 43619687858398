<template>
  <v-breadcrumbs :items="items" class="default-breadcrumbs pl-0">
    <template v-slot:divider>
      <v-icon>mdi-chevron-right</v-icon>
    </template>

    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :disabled="item.disabled"
        @click="redirect(item.href)"
        :class="`${item.first ? 'first-item item' : 'item'}`"
      >
        <template v-if="item.first">
          {{
            item.translatedText
              ? item.translatedText.toUpperCase()
              : item.text.toUpperCase()
          }}
        </template>

        <template v-else>
          {{ item.translatedText || item.text }}
        </template>
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  data() {
    return {
      items: [],
    };
  },

  mounted() {
    this.getBreacrumbs();
  },

  methods: {
    getBreacrumbs() {
      this.items = this.$route.meta.breadcrumbs;
    },
    redirect(e) {
      if (this.$router.currentRoute.path !== e) {
        this.$router.push(e);
      }
    },
  },
};
</script>

<style lang="scss" src="@/assets/sass/components/breadcrumbs.scss">
</style>