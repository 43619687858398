<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card class="add-comment-dialog-card">
      <v-card-title>
        <span>{{ $t("add_comment") }}</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-select
          v-model="form.result_option_id"
          hide-details
          name="graphs"
          id="graphs"
          class="graphsSelector pa-0 mt-4 mb-4"
          append-icon="icomoon  icon-arrow-down"
          :items="commentOptions"
          :menu-props="{
            'offset-y': true,
            'min-width': 200,
            'nudge-left': 17,
            'nudge-top': -3.5,
          }"
        >
        </v-select>
        <v-text-field
          v-model="form.comment"
          :rules="commentRules"
          type="text"
          class="comment-text mt-6 mb-4"
          :label="$t('comment')"
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="d-flex pr-6 pl-6">
        <div class="v-input__control flex-row ">
          <h4 class="cancel-btn" @click="dialog = false">
            {{ $t("cancel") }}
          </h4>
        </div>
        <div class="v-input__control flex-row justify-end">
          <h4 class="cancel-btn" @click="addComment">
            {{ $t("comment") }}
          </h4>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AddCommentDialog",

  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {
        patient_id: this.$route.params.id,
        result_option_id: 1,
        comment: null,
      },
      commentOptions: [
        { value: 1, text: "test" },
        { value: 2, text: "test1" },
        { value: 3, text: "test2" },
      ],
      commentRules: [
        (value) =>
          (value || "").length <= 2200 ||
          this.$t("max_characters", { number: 2200 }),
      ],
    };
  },

  computed: {
    dialog: {
      get() {
        return this.showDialog;
      },
      set(newVal) {
        if (newVal == false) {
          this.$emit("hideDialog");
        }
      },
    },
  },

  methods: {
    async addComment() {
      await this.$axios
        .post("/dashboard/users/contact-history", this.form)
        .then((res) => {
          this.$emit("hideDialog");
        })
        .catch((e) => console.log(e));
    },
  },
};
</script>

<style
  lang="scss"
  src="@/assets/sass/components/dialogs/add-comment-dialog.scss"
></style>
