<template>
	<v-container class="messages-tab">
		<v-row>
			<v-col cols="12">
				<Table :headers="headers" :items="messages" :loading="loading" />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import Table from "@/components/Table.vue";

export default {
	name: "MessageTab",

	data() {
		return {
			loading: false,
			headers: [
				{ text: "Date", value: "sent_when" },
				{ text: "type", value: "message.type_name" },
				{ text: "message", value: "message_content_sent" },
				{ text: "read", value: "is_read" },
				{ text: "rating", value: "rating_h_r_s_message_queue.feedback" },
				{ text: "time of rating", value: "rating_h_r_s_message_queue.updated_at" },
			],
			messages: [],
		};
	},

	computed: {
		isLifeChamps: function () {
			return (
				this.$store.getters["auth/user"]?.theme_config.key == "lifechamps"
			);
		},
	},

	components: {
		Table,
	},

	mounted() {
		this.getHRSMessages();
	},

	methods: {
		async getHRSMessages() {
			const {
				data: { data },
			} = await this.$axios.get(`dashboard/users/${this.$route.params.id}/hrs-messages`);

			data.forEach((item, index, array) => {
				if(item.rating_h_r_s_message_queue != null) {
					const updatedAtDateTime = item.rating_h_r_s_message_queue.updated_at.split('.')[0];
					const updatedAtDate = updatedAtDateTime.split('T')[0];
					const updatedAtTime = updatedAtDateTime.split('T')[1];
					item.rating_h_r_s_message_queue.updated_at = `${updatedAtDate} ${updatedAtTime}`;
					data[index] = item;
				}
			});

			this.messages = data;
		},
	},
};
</script>

<style
  lang="scss"
  src="@/assets/sass/components/views/dashboard/members/id/partial/tabs/messages.scss"
></style>
