<template>
  <v-toolbar dense flat no-gutters class="header">
    <router-link :to="{ name: 'members-list' }" class="logo">
      <img
        alt="logo"
        :src="
          $store.getters['auth/user']
            ? $store.getters['auth/user'].theme_config.header_logo
            : ''
        "
      />
    </router-link>

    <v-text-field
      :label="`${$t('search')} ${$t('members')}`"
      filled
      dense
      rounded
      @input="serachMembers"
      v-if="$route.name == 'members-list'"
    >
      <template v-slot:prepend-inner>
        <span class="icomoon icon-search"></span>
      </template>
    </v-text-field>

    <div class="drop-down p-0 m-0 ml-auto">
      <v-avatar
        size="48"
        :class="
          `profile-pic
        overflow-visible
          messages-icon ${
            $store.getters['auth/user'] &&
            $store.getters['auth/user'].theme_config.key == 'lifechamps'
              ? 'white--text'
              : ''
          }`
        "
        @click="redirect('messages')"
      >
        <v-icon
          small
          class="unread-circle"
          v-if="
            unreadMessagesNum > 0 && $router.currentRoute.name !== 'messages'
          "
        >
          mdi-checkbox-blank-circle
        </v-icon>
        <v-icon large> mdi-email </v-icon>
      </v-avatar>

      <initials-avatar />

      <v-menu class="ml-2 mr-2">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="chevron-down ml-2 mr-2 no-background-hover"
          >
            <v-icon large> mdi-chevron-down </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in dropDownItems"
            :key="index"
            @click="
              item.link != 'logout'
                ? $router.push({ name: item.link })
                : logout()
            "
          >
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-toolbar>
</template>

<script>
import { mapActions } from "vuex";
import InitialsAvatar from "./InitialsAvatar.vue";

export default {
  data() {
    return {
      dropDownItems: [
        {
          title: this.$t("my_account"),
          link: "my-account",
        },
        {
          title: this.$t("logout"),
          link: "logout",
        },
      ],
    };
  },

  computed: {
    unreadMessagesNum: function() {
      return this.$store.getters["messages/unreadMessagesCount"];
    },
  },

  components: { InitialsAvatar },

  beforeMount(){
    if (this.$store.getters['auth/hasAdminTab']) {
      this.dropDownItems.splice(1, 0, {
        title: this.$t("hcp_admin"),
        link: "admin-users",
      });
    }
  },
    
  mounted() {
    this.getUnreadMessagesCount();
    this.listenUnreadMessagesCount();
  },

  methods: {
    ...mapActions({
      logoutAction: "auth/logout",
    }),

    logout() {
      this.logoutAction().then(() => {
        this.$router.replace({
          name: "login",
        });
      });
    },

    serachMembers(e) {
      this.$store.dispatch("members/searchMembers", e);
    },

    redirect(e) {
      if (this.$router.currentRoute.path !== e) {
        this.$router.replace({
          name: e,
        });
      }
    },

    async getUnreadMessagesCount() {
      this.$store.dispatch("messages/getUnreadMessagesCount");
    },

    listenUnreadMessagesCount() {
      this.$echo
        .private(`chat-hcp-${this.$store.getters["auth/user"].id}`)
        .listen(".messenger.chat.unread.messages", (data) =>
          this.$store.commit("messages/SET_UNREAD_MESSAGES_COUNT", data.unread_messages_count)
        );
    },
  },
};
</script>

<style lang="scss" src="@/assets/sass/components/header.scss"></style>
