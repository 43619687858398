<template>
  <v-tabs
    :value="currentTab"
    class="admin-tab custom-tabs"
    background-color="transparent"
    align-with-title
    :color="
      $store.getters['auth/user']
        ? $store.getters['auth/user'].theme_config.primary_color
        : ''
    "
  >
    <v-tab>
      <router-link :to="{ name: 'my-account' }">
        {{ $t("my_account") }}
      </router-link>
    </v-tab>
    <v-tab v-if="$store.getters['auth/hasAdminTab']">
      <router-link :to="{ name: 'admin-users' }">
        {{ $t("admin") }}
      </router-link>
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  name: "AdminTab",

  props: {
    currentTab: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style
  lang="scss"
  src="@/assets/sass/components/views/admin/admin-tabs.scss"
></style>
