<template>
  <v-row class="ves-questionnaire-panel reported_questionnaires">
    <v-col cols="12" class="sub-title ml-2">
      {{ `${$t("reported")}  ${$t("questionnaires")}` }}
    </v-col>
    <v-col cols="12">
      <v-expansion-panels class="pr-1" v-model="openPanel">
        <v-expansion-panel
          v-for="(questionnaire, i) in questionnaires"
          :key="i"
        >
          <v-expansion-panel-header
            class="pa-6"
            :expand-icon="openPanel == i ? 'mdi-minus' : 'mdi-plus'"
          >
            <span class="d-flex-inline pt-2 pb-2 date">
              {{ questionnaire.date }}
            </span>
            <span class="float-right flex-2 score">
              {{ $t("score") }} <strong>{{ questionnaire.score }}</strong>
            </span>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <h3 class="d-inline-block">{{ $t("patient_responses") }}</h3>
            <h4 class="float-right d-inline-block edit-response">
              <v-icon>icon-edit</v-icon> {{ $t("edit_responses") }}
            </h4>
            <template v-for="(question, i) in questions">
              <!-- single MCQ Table question  -->

              <v-simple-table class="mt-10" :key="i">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="text-center text-uppercase"
                        v-for="number in 11"
                        :key="number"
                      >
                        {{ number - 1 }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td v-for="number in 11" :key="number">
                        <div
                          class="circle rounded-circle"
                          :class="{ selected: question.value == number }"
                        >
                          <v-icon v-if="question.value == number"
                            >icon-tick</v-icon
                          >
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "VGPQuestionnairePanels",

  props: {
    title: {
      type: String,
      default: "",
    },
    questionnaires: {
      type: Array,
      default: () => [],
    },
    questions: {
      type: [Array, Object],
      default: () => [],
    },
  },

  data() {
    return {
      openPanel: null,
    };
  },
};
</script>


<style lang="scss" src="@/assets/sass/components/views/dashboard/members/id/partial/questionnaires/components/vgp-questionnaire-panels.scss">
</style>