<template>
  <v-footer color="transparent">
    <v-row justify="center" no-gutters>
      <v-col cols="6">
        <img :src="$store.getters['auth/user'] ? $store.getters['auth/user'].theme_config.footer_logo : ''" alt="" />

        <v-card-text class="pt-0 pb-0 mb-13 copy_rights">
          <v-icon>mdi-alpha-c-circle-outline</v-icon>

          {{ currentDate }} {{ $t("footer_copy_rights") }}
        </v-card-text>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      // If there is error in the get current date request return the local machine current year
      currentDate: new Date().getFullYear(),
    };
  },

  computed: {
    initials: function () {
      let firstLetter =
        this.$store.getters["auth/user"]["first_name"].charAt(0);
      let secondLetter =
        this.$store.getters["auth/user"]["last_name"].charAt(0);

      return firstLetter + secondLetter;
    },
  },

  mounted() {
    this.getCurrentDate();
  },

  methods: {
    async getCurrentDate() {
      let res = await axios.get("/common/server-time-stamp");

      if (res.status == 200) {
        this.currentDate = new Date(res.data.time).getFullYear();
      }
    },
  },
};
</script>

<style lang="scss" src="@/assets/sass/components/footer.scss">
</style>