<template>
  <v-row justify="center" class="position-absolute">
    <v-dialog v-model="dialog" persistent max-width="646">
      <v-card class="check-auto-logout-dialog">
        <v-card-title>
          <v-container fluid>
            <v-row>
              <v-col cols="12" class="p-0 justify-start d-flex">
                <v-icon
                  color="#000"
                  class="ml-auto mb-0 close"
                  @click="handleKeepBrowsing"
                >
                  icon-close
                </v-icon>
              </v-col>
              <v-col cols="12" class="p-0 justify-center d-flex">
                <apexchart
                  type="radialBar"
                  height="189"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>

        <v-card-text class="m-auto">
          <h3 class="main-title">
            {{ $t("check_auto_logout_dialog_title") }}
          </h3>
          <p class="main-body mb-0">
            {{ $t("check_auto_logout_dialog_text") }}
          </p>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
            class="text-capitalize keep-me-signed-in text-uppercase"
            text
            @click="handleKeepBrowsing"
          >
            {{ $t("keep_me_signed_in") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "CheckAutoLogoutDialog",

  data() {
    return {
      autoLogoutCountDown: 60,
      autoLogoutTimerID: null,
    };
  },

  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    dialog: function() {
      return this.showDialog;
    },
    series: function() {
      return [(parseInt(this.autoLogoutCountDown) / 60) * 100];
    },
    chartOptions: function() {
      return {
        chart: {
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            startAngle: 0,
            endAngle: 360,
            hollow: {
              size: "68%",
              margin: 12,
              background: this.$store.getters["auth/user"]?.theme_config
                ?.table_headers_main_color,
              position: "front",
            },
            track: {
              background: "transparent",
            },

            dataLabels: {
              show: true,
              name: {
                offsetY: 24,
                show: true,
                color: "#FFFFFF",
                fontSize: "10px",
              },

              value: {
                show: true,
                formatter: function(val) {
                  return parseInt((val / 100) * 60);
                },
                offsetY: -7,
                color: "#FFFFFF",
                fontFamily: "Inter",
                fontSize: "41px",
                fontWeight: "bold",
                lineHeight: "41px",
              },
            },
          },
        },
        fill: {
          type: "solid",
          strokeWidth: "5%",
          colors: [
            this.$store.getters["auth/user"]?.theme_config
              ?.progress_bar_primary_color,
          ],
          shadeIntensity: 0,
          solid: {
            height: 2,
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: [this.$t("seconds")],
      };
    },
  },

  watch: {
    dialog(newVal) {
      if (newVal == true) {
        // clear check auto logout popups intervals
        this.$store.dispatch("auth/clearCheckAutoLogoutIntervals");

        this.autoLogoutTimerID = setInterval(() => {
          if (this.autoLogoutCountDown > 0) {
            this.autoLogoutCountDown = this.autoLogoutCountDown - 1;
          } else {
            this.logout();
          }
        }, 1000);
      } else {
        this.resetTimer();
      }
    },
  },

  methods: {
    ...mapActions({
      logoutAction: "auth/logout",
    }),
    logout() {
      this.$emit("hideDialog");

      this.logoutAction().then(() => {
        this.$router.replace({
          name: "login",
        });
      });
    },
    handleKeepBrowsing() {
      this.$emit("hideDialog");

      this.$store.dispatch("logActivity", {
        tag: "keep_browsing",
        type: 1,
      });
    },
    resetTimer() {
      clearInterval(this.autoLogoutTimerID);
      this.autoLogoutTimerID = null;
      setTimeout(() => {
        this.autoLogoutCountDown = 60;
      }, 500);
    },
  },
};
</script>

<style
  lang="scss"
  src="@/assets/sass/components/dialogs/check-auto-logout-dialog.scss"
></style>
