<template>
  <v-container>
    <v-row justify="center">
      <img alt="logo" src="@/assets/imgs/logo.svg" class="logo" />
    </v-row>
    <v-row justify="center">
      <login-form />
    </v-row>
  </v-container>
</template>

<script>
import LoginForm from "@/components/LoginForm.vue";

export default {
  name: "Login",

  components: {
    LoginForm,
  },

  beforeCreate() {
    if (this.$store.getters["auth/authenticated"]) {
      return this.$router
        .push({
          name: "members-list",
          params: {
            lang: this.$i18n.locale,
          },
        })
        .catch(() => {});
    }
  },
};
</script>

<style lang="scss" scoped src="@/assets/sass/views/login.scss"></style>
